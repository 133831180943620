import { IStepComponent } from '../types';
import { en } from '../../../i18n';
import { OutlinedInput } from '@mui/material';
import { InputContainer, QuestionHeader } from './styled';
import React from 'react';
import useStep from './useStep';
import PreferredNameOnboarding from '../../OnBoarding/PreferredName';
import { firstNameQuestionId, mainSteps } from '../../OnBoarding/constants';
import { PreferredNameContentContainer, Subtitle, Title } from '../../OnBoarding/styled';
import parse from 'html-react-parser';
import Choices from '../../OnBoarding/Choices';

const { apply } = en;
const { experience } = apply;

const Role = ({ question }: IStepComponent) => {
  const { onChangeAnswer, currentResponse, questionAnswers, setLiveOnboardingRole } = useStep({ question });

  const currentDetails = mainSteps.find(q => q.onboardingQuestionId === question.id);

  console.log(currentResponse, 'CURRENT RESPONSE');

  const getChoices = () => {
    if (!currentDetails) return [];
    const { choicesIcons } = currentDetails;
    return question.content.choices?.map(choice => ({
      id: choice.id,
      content: choice.content.choice?.content.text || '',
      icon: choicesIcons?.[choice.content.explanation?.content.text || ''],
    }));
  };

  const answerRoleQuestion = (id: string) => {
    setLiveOnboardingRole(id);
    onChangeAnswer(id);
  };

  const renderChoices = () => {
    return (
      <>
        <Choices
          choices={getChoices() || []}
          onChange={id => answerRoleQuestion(id)}
          onMultipleChange={id => {}}
          value={currentResponse as string | string[]}
          isMultiple={false}
          isTopicsChoices={false}
          limit={currentDetails?.limit}
          clearMultipleSelection={() => {}}
        />
      </>
    );
  };
  const renderTitle = () => {
    if (!currentDetails) return null;
    const { title, isLargeTitle } = currentDetails;
    const titleText = title || question.content.question.content.text;
    const firstNameResponse = questionAnswers[firstNameQuestionId]?.response || '';
    let parsedName = '';
    try {
      parsedName = firstNameResponse ? JSON.parse(firstNameResponse as string) : '';
    } catch {
      parsedName = firstNameResponse as string;
    }
    const replacedTitle = titleText?.replace('{firstName}', parsedName.toString());
    const parsedTitle = parse(replacedTitle || '');
    return <Title isLargeTitle={isLargeTitle}>{parsedTitle}</Title>;
  };

  return (
    <PreferredNameContentContainer>
      {renderTitle()}

      {renderChoices()}
    </PreferredNameContentContainer>
  );
};

export default Role;
