import { useState, useRef, ChangeEvent, KeyboardEvent, MutableRefObject, useEffect, MouseEvent } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { useMediaQuery, useTheme } from '@mui/material';
import { ISession, ENurseGPTRole } from '../../types';
import useAuthorization from '../useAuthorization';
import useNavigations from '../useNavigations';
import usePersonalInfo from '../usePersonalInfo';
import { isInternalUser, getDateInterval, mapOnboardingRoleToNurseGPTRole } from '../../utils';
import { actions, nurseGPTSelectors } from '../../NurseGPT/redux/slice';
import useRemoveChatWidget from '../useRemoveChatWidget';
import useCommonNurseGPT from './useCommonNurseGPT';
import { endpoints } from '../../constants';

const useNurseGPT = () => {
  useRemoveChatWidget();
  const {
    state: {
      prompt,
      showErrorModal,
      typedResponse,
      svgLoader,
      typedResponseRef,
      inputRef,
      messagesWrapperRef,
      copyToolTipText,
      loadingResponse,
      currentSessionId,
      sessions,
      errors,
      currentSession,
      chat,
      isUnstarted,
      reconnectStatus,
      isReconnecting,
    },
    control: {
      sendJsonMessage,
      setPrompt,
      refresh,
      setCopyTooltipText,
      dispatch,
      handlePromptChange,
      handleStartNewChat,
      handleExampleClick,
      getPlaceholder,
      setLastPromptAttempt,
    },
  } = useCommonNurseGPT({
    socketUrl: endpoints.nurseGPTWebSocket,
    useSocketAuthorization: true,
    useSocketWebSessionAuthorization: false,
  });
  const { getRole, isRoleLoading } = usePersonalInfo();
  const storedRole = getRole();
  const [tipsPopup, setTipsPopup] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [mobileDrawerOpen, setMobileDrawerOpen] = useState(false);
  const [sessionToDelete, setSessionToDelete] = useState<ISession>();
  const [sessionToRename, setSessionToRename] = useState<ISession>();
  const [clickedSessionMenu, setClickedSessionMenu] = useState('');
  const [roleValue, setRoleValue] = useState(mapOnboardingRoleToNurseGPTRole(storedRole));
  const [roleDropdownOpen, setRoleDropdownOpen] = useState(false);
  const [sessionChangeToggle, setSessionChangeToggle] = useState(false);
  const [currentFile, setCurrentFile] = useState<File>();
  const [fileInputKey, setFileInputKey] = useState(0);
  const [firstButtonClick, setFirstButtonClick] = useState(false);
  const theme = useTheme();
  const isMedium = useMediaQuery(theme.breakpoints.down('md'));
  const sessionsTitlesRef = useRef<Record<string, HTMLDivElement | null>>({});
  const sessionsListRef = useRef() as MutableRefObject<HTMLDivElement>;
  const previousMessagesWrapperScrolllHeight = useRef(0);
  const previousMessagesWrapperScrollTop = useRef(0);
  const {
    state: { user },
  } = useAuthorization();
  const { navigateToDashboard } = useNavigations();
  const userFirstLetter = user?.username.charAt(0);
  const userEmail = user?.attributes?.email;
  const { loadingSessions, allSessionsFetched, lastFetchedSession, fileUpload } = useSelector(
    nurseGPTSelectors.allState,
  );
  const fileKeyRef = useRef(fileUpload?.fileKey);
  const noSessions = sessions.length === 0;
  const groupedSessions = sessions.reduce((acc, session) => {
    if (!session.title) return acc;
    const section = getDateInterval(session.createdAt);
    if (acc[section]) acc[section].push(session);
    else acc[section] = [session];
    return acc;
  }, {});
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const showApplyPrompts = JSON.parse(query.get('showApplyPrompts') || 'false');

  const handlePromptSubmit = () => {
    dispatch(actions.submitPrompt({ prompt, currentSessionId, sendJsonMessage, fileUpload }));
    setLastPromptAttempt(prompt);
    setPrompt('');

    if (currentFile) removeUploadedFile();
  };

  const toggleTipsPopup = () => {
    setTipsPopup(!tipsPopup);
  };

  const toggleDeleteModal = () => {
    if (showDeleteModal) setSessionToDelete(undefined);
    setShowDeleteModal(!showDeleteModal);
  };

  const toggleMobileDrawer = () => {
    if (mobileDrawerOpen) setTipsPopup(false);
    setMobileDrawerOpen(!mobileDrawerOpen);
  };

  const onKeyDown = (e: KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.code === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      if ((!prompt && !currentFile) || loadingResponse || fileUpload?.failed) setPrompt(prompt + '\n');
      else handlePromptSubmit();
    }
  };

  const setCurrentSession = (session: ISession) => {
    if (sessionToRename?.sessionId === session.sessionId) return;
    setMobileDrawerOpen(false);
    if (session.sessionId !== currentSessionId) {
      if (session.messages?.length > 0) {
        setSessionChangeToggle(!sessionChangeToggle);
      } else {
        dispatch(actions.fetchSession({ session }));
      }
      dispatch(actions.setCurrentSession({ session }));
    }
  };

  const handleSessionMenuClick = (e: MouseEvent<HTMLElement>, sessionId: string) => {
    e.stopPropagation();
    if (clickedSessionMenu === sessionId) setClickedSessionMenu('');
    else setClickedSessionMenu(sessionId);
  };

  const handleSessionMenuClickaway = () => {
    setClickedSessionMenu('');
  };

  const handleSessionDelete = () => {
    dispatch(actions.deleteSession({ sessionId: sessionToDelete?.sessionId || '' }));
    toggleDeleteModal();
  };

  const handleDeleteClick = (e: MouseEvent<HTMLElement>, session: ISession) => {
    e.stopPropagation();
    setSessionToDelete(session);
    toggleDeleteModal();
  };

  const handleRenameClick = (e: MouseEvent<HTMLElement>, session: ISession) => {
    e.stopPropagation();
    setSessionToRename(session);
    setClickedSessionMenu('');
  };

  const handleRenameBlur = () => {
    const sessionToRenameRef = sessionsTitlesRef.current[sessionToRename?.sessionId || ''];
    if (sessionToRenameRef) {
      sessionToRenameRef.scrollLeft = 0;
      sessionToRenameRef.contentEditable = 'false';
      dispatch(
        actions.renameSession({
          sessionId: sessionToRename?.sessionId || '',
          title: sessionToRenameRef.innerText,
          originalTitle: sessionToRename?.title || '',
        }),
      );
    }
    setSessionToRename(undefined);
  };

  const onRenameKeyDown = (e: KeyboardEvent<HTMLElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      const sessionToRenameRef = sessionsTitlesRef.current[sessionToRename?.sessionId || ''];
      if (sessionToRenameRef) sessionToRenameRef.blur();
    }
  };

  const handleRoleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setRoleValue((e.target as HTMLInputElement).value);
  };

  const handleMobileRoleChange = (role: ENurseGPTRole) => {
    setRoleValue(role);
    toggleRoleDropdown();
  };

  const toggleRoleDropdown = () => {
    setRoleDropdownOpen(!roleDropdownOpen);
  };

  const displaySessionMenuUp = (sessionId: string) => {
    const sessionsListBottom = sessionsListRef.current?.getBoundingClientRect().bottom;
    const sessionItemBottom = (sessionsTitlesRef.current[sessionId]?.getBoundingClientRect().bottom || 0) + 13;
    const availableSpace = sessionsListBottom - sessionItemBottom;
    return availableSpace < 89;
  };

  const handleMessagesScroll = (e: any) => {
    previousMessagesWrapperScrollTop.current = e.target.scrollTop;
    previousMessagesWrapperScrolllHeight.current = e.target.scrollHeight;
    if (e.target.scrollTop === 0 && chat[0] && chat[0].messageId && !currentSession?.allMessagesFetched) {
      dispatch(actions.fetchSession({ session: currentSession || {} }));
    }
  };

  const onFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const file = event.target.files[0];
      setCurrentFile(file);
    }
  };

  const removeUploadedFile = () => {
    dispatch(actions.removeUpload());
    setCurrentFile(undefined);
    setFileInputKey(fileInputKey + 1);
  };

  const cancelFileUpload = () => {
    dispatch(actions.cancelUpload());
    dispatch(actions.deleteFile({ fileKey: fileUpload?.fileKey || '' }));
    removeUploadedFile();
  };

  const onClickAbout = () => {
    setFirstButtonClick(true);
    toggleTipsPopup();
  };

  useEffect(() => {
    if (!noSessions) return;
    if (firstButtonClick) {
      toggleTipsPopup();
    }
  }, [noSessions, firstButtonClick]);

  useEffect(() => {
    if (currentFile) {
      cancelFileUpload();
    }
  }, [currentSessionId]);

  useEffect(() => {
    if (currentFile) dispatch(actions.upload({ file: currentFile, currentSessionId }));
  }, [currentFile]);

  useEffect(() => {
    dispatch(actions.fetchSessions({}));
  }, []);

  useEffect(() => {
    if (!isInternalUser(userEmail)) navigateToDashboard();
  }, [userEmail]);

  useEffect(() => {
    if (!sessionToRename) return;
    const sessionToRenameRef = sessionsTitlesRef.current[sessionToRename?.sessionId];
    if (sessionToRenameRef) {
      sessionToRenameRef.contentEditable = 'true';
      sessionToRenameRef.focus();
      const selection = window.getSelection();
      if (selection) selection.selectAllChildren(sessionToRenameRef);
      if (selection) selection.collapseToEnd();
      sessionToRenameRef.scrollLeft = sessionToRenameRef.scrollWidth;
    }
  }, [sessionToRename]);

  useEffect(() => {
    setRoleValue(mapOnboardingRoleToNurseGPTRole(storedRole));
  }, [storedRole]);

  useEffect(() => {
    messagesWrapperRef.current?.scrollBy({
      behavior: window.innerWidth > 900 ? 'smooth' : 'auto',
      left: 0,
      top: messagesWrapperRef.current.scrollHeight,
    });
  }, [sessionChangeToggle]);

  useEffect(() => {
    if (lastFetchedSession === currentSessionId && currentSession?.numberOfFetches === 1)
      messagesWrapperRef.current?.scrollBy({
        behavior: window.innerWidth > 900 ? 'smooth' : 'auto',
        left: 0,
        top: messagesWrapperRef.current.scrollHeight,
      });
  }, [lastFetchedSession]);

  useEffect(() => {
    if (lastFetchedSession === currentSessionId && currentSession?.numberOfFetches > 1 && messagesWrapperRef.current) {
      const newScrollHeight = messagesWrapperRef.current.scrollHeight;
      const heightDifference = newScrollHeight - previousMessagesWrapperScrolllHeight.current;
      messagesWrapperRef.current.scrollTop = previousMessagesWrapperScrollTop.current + heightDifference;
    }
  }, [currentSession?.numberOfFetches]);

  // TODO: Refactor to use onScroll react event, to be consistent with messages pagination
  useEffect(() => {
    if (sessionsListRef.current) {
      sessionsListRef.current.onscroll = () => {
        if (
          sessionsListRef.current.scrollHeight -
            sessionsListRef.current.offsetHeight -
            sessionsListRef.current.scrollTop <=
            1 &&
          !loadingSessions &&
          !allSessionsFetched
        )
          dispatch(actions.fetchSessions({ lastSession: sessions[sessions.length - 1] }));
      };
    }
  }, [sessions, loadingSessions]);

  useEffect(() => {
    fileKeyRef.current = fileUpload?.fileKey;
  }, [fileUpload?.fileKey]);

  useEffect(() => {
    const handleUploadCleanup = () => {
      if (fileKeyRef.current) dispatch(actions.deleteFileOnRefresh({ fileKey: fileKeyRef.current || '' }));
    };
    window.addEventListener('beforeunload', handleUploadCleanup);
    return () => {
      window.removeEventListener('beforeunload', handleUploadCleanup);
    };
  }, []);

  return {
    state: {
      prompt,
      chat,
      messagesWrapperRef,
      typedResponseRef,
      userFirstLetter,
      loadingResponse,
      isUnstarted,
      sessions,
      noSessions,
      tipsPopup,
      groupedSessions,
      mobileDrawerOpen,
      loadingSessions,
      inputRef,
      typedResponse,
      clickedSessionMenu,
      showDeleteModal,
      sessionToDelete,
      sessionToRename,
      sessionsTitlesRef,
      sessionsListRef,
      roleValue,
      roleDropdownOpen,
      currentSession,
      svgLoader,
      showErrorModal,
      storedRole,
      isRoleLoading,
      currentFile,
      fileInputKey,
      copyToolTipText,
      fileUpload,
      errors,
      firstButtonClick,
      isMedium,
      reconnectStatus,
      isReconnecting,
      showApplyPrompts,
    },
    control: {
      setPrompt,
      handlePromptChange,
      handlePromptSubmit,
      handleStartNewChat,
      onKeyDown,
      toggleTipsPopup,
      setCurrentSession,
      toggleMobileDrawer,
      handleExampleClick,
      getPlaceholder,
      handleSessionMenuClick,
      handleSessionMenuClickaway,
      handleSessionDelete,
      toggleDeleteModal,
      handleDeleteClick,
      handleRenameClick,
      handleRenameBlur,
      onRenameKeyDown,
      handleRoleChange,
      handleMobileRoleChange,
      toggleRoleDropdown,
      setRoleDropdownOpen,
      displaySessionMenuUp,
      refresh,
      handleMessagesScroll,
      onFileChange,
      cancelFileUpload,
      setCopyTooltipText,
      setFirstButtonClick,
      onClickAbout,
      reconnectStatus,
    },
  };
};

export default useNurseGPT;
