import { IAttempt, IQuestion, IQuestionAnswersState, EQuestionTypes, ILooseObject, EAttemptTypes } from '../types';
import { findLastIndex, isAttemptDone } from '../utils';
import QuestionResponseTransformer from './DataTransformers/QuestionResponseTransformer';
interface IInputQuestions {
  [key: string]: IQuestion;
}

class PopulateAnswers {
  private questions: IInputQuestions;
  private attempt: IAttempt;
  private questionResponseTransformer: QuestionResponseTransformer;
  private checkResponseTime: boolean;

  public constructor(questions: IInputQuestions, attempt: IAttempt, checkResponseTime = false) {
    this.questions = questions;
    this.attempt = attempt;
    this.questionResponseTransformer = new QuestionResponseTransformer();
    this.checkResponseTime = checkResponseTime;
  }

  public getQuestionAnswers() {
    const questionAnswers = this.attempt.questions.reduce((acc: IQuestionAnswersState, attemptQuestion, index) => {
      const question = this.questions[attemptQuestion.questionId];
      if (attemptQuestion.response) {
        const response = [
          EAttemptTypes.APPLY,
          EAttemptTypes.APPLY_ENHANCE,
          EAttemptTypes.CLICKS,
          EAttemptTypes.APPLY_JOB_APPS,
        ].includes(this.attempt.quizType)
          ? this.questionResponseTransformer.transformParse(attemptQuestion.response)
          : this.questionResponseTransformer.transform(attemptQuestion.response);
        if (question.questionType === EQuestionTypes.NGN_CASE_STUDY) {
          acc[attemptQuestion.questionId] = {
            index,
            value: '',
            ctime: attemptQuestion.ctime,
            subAnswers: JSON.parse(attemptQuestion.mark || '[]').reduce(
              (acc: ILooseObject, subMark: ILooseObject, index: number) => {
                acc[subMark.id] = {
                  value: response[index] || '',
                  isDone: Boolean(response[index]),
                  parsedMark: subMark,
                };
                return acc;
              },
              {},
            ),
          };
        } else {
          acc[attemptQuestion.questionId] = {
            index,
            value: response,
            parsedMark: JSON.parse(attemptQuestion.mark || '{}'),
            isDone: true,
            ctime: attemptQuestion.ctime,
          };
        }
      }
      return acc;
    }, {});

    return questionAnswers;
  }

  public getCurrentQuestion() {
    const result = {
      questionId: '',
      isDone: false,
      questionIndex: 0,
    };

    // TO account for "0" answers
    const currentQuestionIndex = findLastIndex(
      this.attempt.questions,
      question => Boolean(question.response) || (this.checkResponseTime && question.ctime),
    );
    if (isAttemptDone(this.attempt, false, this.checkResponseTime)) result.isDone = true;
    const questionIndex = currentQuestionIndex === -1 ? 0 : currentQuestionIndex;
    result.questionId = this.attempt.questions[questionIndex].questionId;
    result.questionIndex = questionIndex;
    return result;
  }

  public populate() {
    return {
      questionAnswers: this.getQuestionAnswers(),
      currentQuestionInfo: this.getCurrentQuestion(),
    };
  }
}

export default PopulateAnswers;
