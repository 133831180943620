import { useContext, useEffect } from 'react';
import paths, { defaultPaths } from './paths';
import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';
import { AppContext } from '../../AppContext';
import { Route, Redirect } from 'react-router';
import { Switch } from 'react-router-dom';

const Routes = () => {
  const { isLoggedIn } = useContext(AppContext);

  const renderRoutes = (isPublic: boolean) => {
    const pathsSource = isPublic ? paths.public : paths.private;
    const Component = isPublic ? PublicRoute : PrivateRoute;
    return Object.values(pathsSource).map(route => <Component key={route.path} {...route} />);
  };

  return (
    <Switch>
      {renderRoutes(true)}
      {renderRoutes(false)}
      <Route>{isLoggedIn ? <Redirect to={defaultPaths.private} /> : <Redirect to={defaultPaths.public} />}</Route>
    </Switch>
  );
};

export default Routes;
