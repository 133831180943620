import { IStepComponent } from '../types';
import { FAQContainer } from './styled';
import { en } from '../../../i18n';
import parse from 'html-react-parser';
import { ExitPageContainer, ContentContainer } from './styled';
import Profile from './Profile';
import Jobs from './Jobs';

interface IExit extends IStepComponent {
  supported: boolean;
}

const { apply } = en;
const { exit } = apply;

const Exit = ({ supported }: IExit) => {
  return (
    <ExitPageContainer>
      <ContentContainer>
        <Profile />
        <Jobs />
        <FAQContainer>{parse(exit.faq)}</FAQContainer>
      </ContentContainer>
    </ExitPageContainer>
  );
};

export default Exit;
