import { useLocation } from 'react-router-dom';
import { useDispatch, useNavigations, useSeoTags } from '../../hooks';
import { useEffect } from 'react';
import { Body, ContentContainer, HeaderText, PageContainer, StyledButton } from './styled';
import { en } from '../../../i18n';
import img from '../../../images/icons/apply/file-success.png';
import { IconContainer } from '../../ReviewResults/styled';
import Button from '../../components/Button';
import { actions } from '../redux/slice';
import { EApplyTypes } from '../../types';

const { apply } = en;

interface IRouteState {
  isRedirect: boolean;
}

const AlreadySubmitted = () => {
  const location = useLocation<IRouteState>();
  const { navigateToNurseGPT, navigateToApply } = useNavigations();
  const { dispatch } = useDispatch();
  const seo = useSeoTags({ pageName: 'apply' });

  const onReview = () => {
    //dispatch(actions.setCurrentType({ type: EApplyTypes.BASE }));
    dispatch(actions.setCurrentQuestionIndex({ index: -1 }));
    navigateToApply();
  };

  useEffect(() => {
    if (!location.state?.isRedirect) navigateToNurseGPT();
  }, []);

  return (
    <PageContainer>
      {seo}
      <ContentContainer>
        <IconContainer>
          <img src={img} width={160} height={120} />
        </IconContainer>

        <HeaderText>{apply.alreadySubmitted.header}</HeaderText>

        <Body>{apply.alreadySubmitted.description}</Body>

        <StyledButton onClick={onReview} variant="primary">
          {apply.alreadySubmitted.primaryButton}
        </StyledButton>
        <Button onClick={navigateToNurseGPT} variant="underlinedSpan">
          {apply.alreadySubmitted.secondaryButton}
        </Button>
      </ContentContainer>
    </PageContainer>
  );
};

export default AlreadySubmitted;
