import { styled } from '@mui/material/styles';
import { colors } from '../../themes';
import { FormPopup } from '../OnBoarding/styled';
import { Banner, SmartQuizContainer } from '../SmartQuiz/styled';
import { opacityInAnimation, zoomOutAnimation } from '../sharedStyled';
import { AuthContainerOverride, PageContainer, RadialBackground } from '../Auth/styled';
import { shouldForwardProps } from '../utils';
import { ModalOverlay } from '../Membership/styled';
import Button from '../components/Button';

interface IColumnDetails {
  width?: number;
  leftMargin?: string;
  leftWrappedMargin?: string;
}
interface IModalOverlay {
  stickToTop?: boolean;
}

interface IContainerHeight {
  isHeaderVisible?: boolean;
}

const FormPopupOveride = styled(FormPopup)`
  min-height: 0;
  ${props => props.theme.breakpoints.down('md')} {
    margin-top: 30px;
    margin-bottom: 30px;
  }
  ${props => props.theme.breakpoints.down('sm')} {
    margin-top: 0px;
    margin-bottom: 0px;
  }
`;

const ApplyContainer = styled('div')`
  padding-top: 64px;
  padding-left: 32px;
  padding-right: 32px;
  padding-bottom: 32px;
  ${props => props.theme.breakpoints.down('sm')} {
    padding-top: 16px;
    padding-bottom: 32px;
  }
`;

const Title = styled('h1')`
  color: ${colors.primary};
  text-align: center;
  font-weight: 400;
  font-size: 2rem;
  .bold {
    font-weight: 700;
  }

  .black {
    color: ${colors.black};
  }
  margin: 0;
`;

const TitlesContainer = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 16px;
  path {
    fill: black !important;
  }
  margin-bottom: 64px;
`;

const ColumnsContainer = styled('div')`
  display: flex;
  ${props => props.theme.breakpoints.down('lg')} {
    flex-wrap: wrap;
    row-gap: 200px;
  }

  margin-bottom: 250px;
  margin-top: 64px;
  justify-content: center;
  ${props => props.theme.breakpoints.down('md')} {
    flex-direction: column;
    gap: 32px;
    margin-bottom: 64px;
  }
`;

const Column = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 33.33333333333%;
  ${props => props.theme.breakpoints.down('lg')} {
    width: 50%;
  }
  ${props => props.theme.breakpoints.down('md')} {
    width: auto;
  }
`;

const ColumnTitle = styled('div')`
  font-weight: 700;
  display: flex;
  flex-direction: column;
  font-size: 2rem;
  margin-bottom: 16px;
  color: ${colors.lightblack};
  width: 100%;

  svg {
    width: 3rem;
    height: 3rem;
  }
  path {
    fill: ${colors.primary} !important;
  }
  ${props => props.theme.breakpoints.down('md')} {
    text-align: center;
  }
`;

const RelativeTitle = styled('div')`
  position: relative;
`;

const IconContainer = styled('div')`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const ColumnSubTitle = styled('div')`
  text-align: left;
  margin-bottom: 24px;
  font-weight: 700;
  color: ${colors.lightblack};
  width: 100%;
  ${props => props.theme.breakpoints.down('lg')} {
    margin-bottom: 12px;
  }
  ${props => props.theme.breakpoints.down('md')} {
    text-align: center;
  }
  .underline {
    text-decoration: underline;
  }
`;

const ColumnDetail = styled('div')`
  color: ${colors.lightblack};
  font-weight: 400;
  min-width: 300px;

  span {
    font-weight: 700;
  }
  .underline {
    text-decoration: underline;
  }

  ${props => props.theme.breakpoints.down('md')} {
    width: 100%;
    text-align: center;
    min-width: 0;
  }
`;

const ButtonPreText = styled('div')`
  font-size: 1.25rem;
  font-weight: 700;
  text-align: center;
  .smartn {
    color: ${colors.primary};
  }
  .smartn-apostrophe {
    color: ${colors.smartNApostrophe};
  }
`;
const ButtonTextContainer = styled('div')`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  ${props => props.theme.breakpoints.down('md')} {
    button {
      width: 100%;
    }
  }
`;

const SmartQuizContainerOverride = styled(SmartQuizContainer)`
  box-sizing: border-box;
  filter: drop-shadow(0px 0px 40px rgb(209, 203, 246));
  background: ${colors.white};
  width: min(1240px, 86vw);
  min-height: min(80vh, 860px);
  ${props => props.theme.breakpoints.down('sm')} {
    min-height: min(90vh, 860px);
  }
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 0;
  @media only screen and (min-device-width: 375px) and (max-device-width: 950px) and (orientation: landscape), {
    min-height: min(85vh, 860px);
  }
  @media only screen and (max-height: 600px) and (orientation: landscape) {
    min-height: min(85vh, 860px);
  }

  border-radius: 16px;
  position: relative;
  margin-top: 30px;
  margin-bottom: 124px;

  ${props => props.theme.breakpoints.down('sm')} {
    margin-top: 0px;
    margin-bottom: 60px;
  }

  ${zoomOutAnimation()}
`;

const BannerOverride = styled(Banner)`
  height: 70px;
  ${props => props.theme.breakpoints.down('$xs')} {
    height: 100px;
  }
  display: flex;
  ${props => props.theme.breakpoints.down('sm')} {
    flex-direction: column;
    flex-wrap: nowrap;
    padding-left: 5%;
    padding-right: 5%;
  }
  align-items: base-line;
  justify-content: space-between;
`;

const BannerTitle = styled('h1')`
  margin: 0;
  color: ${colors.black};
  .purple {
    color: ${colors.primary};
  }
  font-weight: 700;

  font-size: 1.25rem;
`;

const ProgressText = styled('span')`
  color: ${colors.primary};
  font-weight: 700;
  margin-top: 1px;
  ${props => props.theme.breakpoints.down('sm')} {
    margin-top: 0;
  }
`;

const QuestionContainer = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 64px;
  padding-left: 32px;
  padding-right: 32px;
  min-height: min(400px, 70vh);

  width: 80%;
  margin-left: auto;
  margin-right: auto;
  ${props => props.theme.breakpoints.down('sm')} {
    width: auto;
    padding-bottom: 32px;
    min-height: unset;
  }
  ${opacityInAnimation()};
`;

const LocationQuestionContainer = styled(QuestionContainer)`
  ${props => props.theme.breakpoints.down('sm')} {
    margin-left: unset;
    margin-right: 16px;
    padding-left: 16px;
  }
  margin-bottom: 88px;
`;

const RadialBackgroundOverride = styled(RadialBackground)`
  position: relative;
`;

const AuthContainerOverrideApply = styled(AuthContainerOverride, {
  shouldForwardProp: prop => shouldForwardProps(prop, ['isHeaderVisible']),
})<IContainerHeight>(
  ({ isHeaderVisible }) => `
  height: auto;
  min-height: ${isHeaderVisible ? 'calc(100vh - 64px)' : 'calc(100vh)'};
`,
);

const ColumnDetails = styled('div', {
  shouldForwardProp: prop => shouldForwardProps(prop, ['width', 'leftMargin', 'leftWrappedMargin']),
})<IColumnDetails>(
  ({ width, leftMargin, theme, leftWrappedMargin }) => `
  font-size: 1rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position:absolute;
  left:2px;
  top: 60px;



  ${theme.breakpoints.down('md')} {
   width:100%;
   margin-left:0;
   position:static;
   margin-top:10px;
  }
  
`,
);

const ApplyModalOverlay = styled(ModalOverlay, {
  shouldForwardProp: prop => shouldForwardProps(prop, ['open', 'stickToTop']),
})<IModalOverlay>(
  ({ stickToTop, theme }) => `
    min-height: calc(100vh - 110px);
    position:absolute;
    top:0px;

  `,
);

const ExitSmartQuizContainer = styled(SmartQuizContainerOverride)`
  min-height: 0;
`;

const ExitQuestionContainer = styled(QuestionContainer)``;

const EnhanceDoneText = styled('div')`
  font-style: italic;
  font-size: 0.875rem;
  color: ${colors.grey500};
  font-weight: 600;
`;

const SaveExitButton = styled(Button)`
  position: absolute;
  right: 0;
  bottom: -64px;

  ${props => props.theme.breakpoints.down('sm')} {
    bottom: -32px;
    right: unset;
  }
`;

const PageContainerOverride = styled(PageContainer, {
  shouldForwardProp: prop => shouldForwardProps(prop, ['isHeaderVisible']),
})<IContainerHeight>(
  ({ isHeaderVisible }) => `
  height: auto;
  min-height: ${isHeaderVisible ? 'calc(100vh - 64px)' : 'calc(100vh)'};
`,
);

const UnstartedPageContainerOverride = styled(PageContainer)`
  background-color: ${colors.white};
  min-height: auto;
  ${opacityInAnimation()};
`;

const UnstartedContainer = styled('div')`
  margin: 60px 7vw;
  display: flex;
  justify-content: space-between;
  img {
    width: 35%;
    object-fit: contain;
    object-position: top;
  }
  ${props => props.theme.breakpoints.down('md')} {
    margin: 60px 4.5vw;
    img {
      display: none;
    }
  }
`;

const UnstartedTitle = styled('div')`
  font-size: clamp(2.5rem, 2.5vw, 4rem);

  color: ${colors.black};
  margin-bottom: 16px;
  ${props => props.theme.breakpoints.down('md')} {
    font-size: 2rem;
  }
`;

const UnstartedSubtitle = styled('div')`
  color: ${colors.black};
  margin-bottom: 32px;
`;

const UnstartedBulletPoint = styled('div')`
  font-weight: 700;
  color: ${colors.black};
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  a {
    font-weight: normal;
    color: ${colors.purple30};
    text-decoration: none;
    margin-left: 4px;
    display: contents;
  }
  svg {
    margin-right: 8px;
    flex: 0 0 auto;
  }
  ${props => props.theme.breakpoints.down('md')} {
    line-height: 21.79px;
    align-items: flex-start;
    svg {
      width: 18px;
      height: 18px;
    }
  }
`;

const UnstartedFooter = styled('div')`
  color: ${colors.black};
  margin-top: 16px;
  margin: 32px 0;
`;

const UnstartedButtonOverrid = styled(Button)`
  padding: 16px 32px;
  span:nth-child(1) {
    display: flex;
    align-items: center;
    gap: 10px;
    svg {
      width: 16px;
      height: 16px;
      color: ${colors.white};
      path {
        fill: ${colors.white};
      }
    }
  }
  ${props => props.theme.breakpoints.down('sm')} {
    padding: 16px 32px;
    span {
      justify-content: center;
    }
  }
`;

export {
  ApplyContainer,
  Title,
  TitlesContainer,
  ColumnsContainer,
  Column,
  ColumnTitle,
  ColumnSubTitle,
  ColumnDetail,
  ButtonTextContainer,
  ButtonPreText,
  FormPopupOveride,
  SmartQuizContainerOverride,
  BannerOverride,
  BannerTitle,
  ProgressText,
  QuestionContainer,
  RadialBackgroundOverride,
  LocationQuestionContainer,
  IconContainer,
  ColumnDetails,
  RelativeTitle,
  ApplyModalOverlay,
  ExitSmartQuizContainer,
  ExitQuestionContainer,
  EnhanceDoneText,
  AuthContainerOverrideApply,
  SaveExitButton,
  PageContainerOverride,
  UnstartedPageContainerOverride,
  UnstartedContainer,
  UnstartedTitle,
  UnstartedSubtitle,
  UnstartedBulletPoint,
  UnstartedFooter,
  UnstartedButtonOverrid,
};
