import { useSelector } from 'react-redux';
import { useDispatch, useNavigations } from '../hooks';
import { actions, applySelector } from './redux/slice';
import { EApplyStep, EApplyTypes } from '../types';
import { useEffect, useRef } from 'react';
import { JOB_APP_QUIZ_ID } from './constants';

interface IUseApplyJobAttempts {
  startAttempt?: boolean;
}

const useApplyJobAttempts = ({ startAttempt }: IUseApplyJobAttempts) => {
  const {
    loading: loadingApply,
    currentQuestionIndex: stepIndex,
    currentType,
    loadingFetchJobApps,
    jobAppAttempts,
    jobAppsFetched,
    jobAppId,
    applyQuizFetched,
    attempts,
  } = useSelector(applySelector.allState);
  const isInitStarted = useRef(false);
  const { dispatch } = useDispatch();

  useEffect(() => {
    if (!startAttempt || isInitStarted.current) return;

    if (currentType !== EApplyTypes.JOB_APP || !jobAppId || loadingFetchJobApps || !jobAppsFetched || !applyQuizFetched)
      return;

    isInitStarted.current = true;
    if (jobAppAttempts.length > 0 || attempts.length > 0) {
      return;
    }

    console.log('Starting Quiz', jobAppAttempts, 'Apply Attempts:', attempts);
    //dispatch(actions.setCurrentStep({ step: EApplyStep.QUIZ_STARTED }));
    dispatch(actions.attemptQuiz({ quizId: JOB_APP_QUIZ_ID, jobId: jobAppId }));
  }, [jobAppId, loadingFetchJobApps, applyQuizFetched, currentType]);
};

export default useApplyJobAttempts;
