import { IStepComponent } from '../../types';
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { TFieldValues } from '../../../components/Form/Fields/types';
import useStep from '../useStep';
import { ReactComponent as PurpleCheckMark } from '../../../../images/icons/apply/purpleCheckMark.svg';
import { ReactComponent as GreyCheckMark } from '../../../../images/icons/apply/greyCheckMark.svg';
import {
  Details,
  ExpandableContainer,
  OptInHeader,
  PopupContainer,
  SubTitle,
  Title,
  ButtonContainer,
  ErrorMessage,
} from './styled';

import Button from '../../../components/Button';
import { useEffect, useState } from 'react';
import { isArray } from 'lodash';
import { en } from '../../../../i18n';
import { useSelector } from 'react-redux';
import { applySelector } from '../../redux/slice';
import { NOTIFICATIONS_ID } from '../../constants';

const { apply } = en;

const OptIn = ({ question, goNext }: IStepComponent) => {
  const { onChangeAnswer, currentResponse } = useStep({ question });
  const [isNotifExpanded, setIsNotifExpanded] = useState(false);
  const { loadingAnswer } = useSelector(applySelector.allState);

  useEffect(() => {
    if (!isArray(currentResponse)) return;
    const isNotifChecked = currentResponse.includes(NOTIFICATIONS_ID);
    if (!isNotifChecked) setIsNotifExpanded(true);
  }, [currentResponse]);

  const onClickNotifications = () => {
    if (!isArray(currentResponse)) return;
    const isNotifChecked = currentResponse.includes(NOTIFICATIONS_ID);
    if (!isNotifChecked) return;
    setIsNotifExpanded(!isNotifExpanded);
  };

  useEffect(() => {
    const pickedChoices = question.content.choices?.map(choice => choice.id) || [];
    onChangeAnswer(pickedChoices);
  }, []);

  const onChange = (id: string) => {
    if (!isArray(currentResponse)) return;
    const isChecked = currentResponse.includes(id);
    let nextResponse = currentResponse;
    if (isChecked) nextResponse = currentResponse.filter(item => item !== id);
    else nextResponse = [...currentResponse, id];
    onChangeAnswer(nextResponse);
  };

  const buttonDisabled = isArray(currentResponse) && !currentResponse?.includes(NOTIFICATIONS_ID);

  const renderChoices = () => {
    const { choices } = question.content;
    return choices?.map(choice => {
      const isChecked = isArray(currentResponse) && currentResponse?.includes(choice.id);
      const renderCheckMark = () => {
        const CheckMarkComponent = isChecked ? PurpleCheckMark : GreyCheckMark;
        return (
          <CheckMarkComponent
            onClick={(e: any) => {
              e.stopPropagation();
              e.preventDefault();
              onChange(choice.id);
            }}
          />
        );
      };

      const renderDetails = () => {
        const defaultComp = <Details>{choice.content.explanation?.content.text}</Details>;
        if (choice.id !== NOTIFICATIONS_ID || isChecked) return defaultComp;
        return <ErrorMessage>{apply.optIn.errorDisc}</ErrorMessage>;
      };

      return (
        <Accordion
          disableGutters
          key={choice.id}
          sx={{
            borderBottom: '1px solid rgb(214,214,214)', // Persistent bottom border
            boxShadow: 'none', // Removes default shadow
            '&:before': {
              display: 'none', // Removes the default Material-UI divider line
            },
            '&.Mui-expanded': {
              margin: 0, // Prevents margin expansion when expanded
              marginBottom: '0 !important',
            },
            paddingBottom: '16px',
          }}
          {...(choice.id === NOTIFICATIONS_ID && { expanded: isNotifExpanded, onClick: onClickNotifications })}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1-content" id="panel1-header">
            <OptInHeader>
              {renderCheckMark()}
              <div>{choice.content.choice?.content.text}</div>
            </OptInHeader>
          </AccordionSummary>
          <AccordionDetails
            sx={{
              paddingTop: '4px',
              paddingBottom: 0,
            }}
          >
            {renderDetails()}
          </AccordionDetails>
        </Accordion>
      );
    });
  };

  const { content } = question;
  const { text: title } = content.question.content;
  const subTitle = content.explanation?.content.text;

  return (
    <PopupContainer>
      <Title>{title}</Title>
      <SubTitle>{subTitle}</SubTitle>
      <ExpandableContainer>{renderChoices()}</ExpandableContainer>
      <ButtonContainer>
        <Button loading={loadingAnswer} onClick={() => goNext?.()} disabled={buttonDisabled} variant="primary">
          {apply.optIn.button}
        </Button>
      </ButtonContainer>
    </PopupContainer>
  );
};

export default OptIn;
