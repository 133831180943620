import { PayloadAction } from '@reduxjs/toolkit';
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';
import { put, takeLatest, call } from 'redux-saga/effects';
import { actions } from './slice';
import { Auth } from 'aws-amplify';
import {
  ConfirmSignUpResponse,
  SignInResponse,
  SignUpResponse,
  Eevents,
  CognitoUser,
  EGoogleTags,
  EUserRole,
  IAnswerQuestionResponse,
  IStartQuizResponse,
} from '../../types';
import { applyPath, forceChangePasswordPath, passwordResetPath, registerConfirmationPath } from '../../Router/paths';
import { en } from '../../../i18n';
import { EAWSErrorCodes } from '../constants';
import {
  AuthErrorTransformer,
  CurrentProfile,
  AuthUserTransfomer,
  GoogleTags,
  QuestionResponseTransformer,
} from '../../classes';
import { actions as eventActions } from '../../events/redux/slice';
import { actions as subscriptionActions } from '../../Membership/redux/slice';
import { ROLE_PLACE_HOLDER_ID, roleQuestionId } from '../../OnBoarding/constants';
import axiosRequest, { ERequestMethods } from '../../axios';
import { endpoints } from '../../constants';
import UserRolesTransformer from '../../classes/DataTransformers/UserRolesTransformer';
import { pollRequest } from '../../utils';
import { JOB_APP_QUERY_PARAM } from '../../Apply/constants';
import LocalStorageWithTTL from '../../classes/LocalStorageWithTTL';
//import LocalStorageWithTTL from '../../classes/LocalStorageWithTTL';
//import { JOB_APP_QUERY_PARAM } from '../../Apply/constants';

const { registerConfirmation, setNewPassword } = en;

function* login(action: PayloadAction<{ userName: string; password: string; history: any }>) {
  const { userName, password, history } = action.payload;
  try {
    const user: SignInResponse = yield call([Auth, Auth.signIn], userName, password, { signin: 'true' });
    const transformedUser = new AuthUserTransfomer().transformOut(user);
    CurrentProfile.setProfile(transformedUser);
    yield put(actions.loginSuccess({ user: transformedUser, history }));

    if (transformedUser.isTemp) history.push({ pathname: forceChangePasswordPath });
    else yield put(eventActions.sendEventRequest({ event: { detailType: Eevents.LOGIN } }));
  } catch (error) {
    let authError = new AuthErrorTransformer().transformOut(error);
    switch (authError.errors.responseCode) {
      case EAWSErrorCodes.UserNotConfirmedException:
        history.push(registerConfirmationPath);
        break;
      case EAWSErrorCodes.resourceNotFoundException:
        authError = { errors: { message: en.login.error } };
        break;
    }

    yield put(actions.loginFailure(authError));
  }
}

function* federatedLogin() {
  try {
    yield Auth.federatedSignIn({ provider: CognitoHostedUIIdentityProvider.Google });
  } catch (error) {
    const authError = new AuthErrorTransformer().transformOut(error);
    yield put(actions.federatedLoginFailure(authError));
  }
}

function* signup(
  action: PayloadAction<{ userName: string; password: string; email: string; fullName: string; history: any }>,
) {
  try {
    const { userName, password, email, fullName, history } = action.payload;
    const attributes = {
      name: fullName,
      email,
    };

    const response: SignUpResponse = yield call([Auth, Auth.signUp], { username: userName, password, attributes });
    const { user, userConfirmed } = response;

    const authTransformer = new AuthUserTransfomer();
    const transformedUser = user
      ? authTransformer.transformOut(user, userConfirmed)
      : authTransformer.constructUser(userName);
    yield put(actions.signUpSuccess({ user: transformedUser, history }));
    history.push(`${registerConfirmationPath}`);
    CurrentProfile.setProfile(transformedUser);
    const googleTag = new GoogleTags(EGoogleTags.SIGN_UP, { userEmail: email });
    googleTag.fireEvent();
    yield put(actions.signUpSuccess({ user: transformedUser, history }));
  } catch (error) {
    const authError = new AuthErrorTransformer().transformOut(error);
    yield put(actions.signUpFailure(authError));
  }
}

function* confirmAccount(action: PayloadAction<{ userName: string; code: string; history: any }>) {
  try {
    const { userName, code, history } = action.payload;
    const confirmSignUpResponse: ConfirmSignUpResponse = yield call([Auth, Auth.confirmSignUp], userName, code, {
      forceAliasCreation: true,
    });
    const googleTag = new GoogleTags(EGoogleTags.VERIFIED_SIGN_UP, { userEmail: userName });
    googleTag.fireEvent();
    history.push('/');
    yield put(
      actions.confirmAccountSuccess({
        successFeedback: {
          title: registerConfirmation.confirmedSuccessTitle,
          subTitle: registerConfirmation.confirmedSuccessSubTitle,
        },
      }),
    );
  } catch (error) {
    const authError = new AuthErrorTransformer().transformOut(error);
    yield put(actions.ConfirmAccountFailure(authError));
  }
}

function* resendConfirmationCode(action: PayloadAction<{ userName: string }>) {
  try {
    const { userName } = action.payload;
    yield call([Auth, Auth.resendSignUp], userName);
    yield put(
      actions.resendConfirmationCodeSuccess({
        successFeedback: {
          title: registerConfirmation.resendSuccessTitle,
          //subTitle: false,
        },
      }),
    );
  } catch (error) {
    const authError = new AuthErrorTransformer().transformOut(error);
    yield put(actions.resendConfirmationCodeFailure(authError));
  }
}

function* sendPasswordResetCode(action: PayloadAction<{ email: string; history: any }>) {
  try {
    const { email, history } = action.payload;
    yield call([Auth, Auth.forgotPassword], email, { forgotPassword: 'true' });
    yield put(actions.forgotPasswordSuccess());
    history.push({ pathname: passwordResetPath, state: { email } });
  } catch (error) {
    const authError = new AuthErrorTransformer().transformOut(error);
    yield put(actions.forgetPasswordFailure(authError));
  }
}

function* changePasswordRequest(
  action: PayloadAction<{ email: string; history: any; code: string; password: string }>,
) {
  const { email, history, code, password } = action.payload;
  try {
    yield call([Auth, Auth.forgotPasswordSubmit], email, code, password);
    yield put(
      actions.changePasswordSuccess({
        successFeedback: {
          title: setNewPassword.successTitle,
          subTitle: setNewPassword.successSubTitle,
        },
      }),
    );
  } catch (error) {
    const authError = new AuthErrorTransformer().transformOut(error);
    switch (authError.errors.responseCode) {
      case EAWSErrorCodes.InvalidForgetPasswordCode:
        history.push({ pathname: passwordResetPath, state: { email } });
        break;
    }
    yield put(actions.changePasswordFailure(authError));
  }
}

function* watchForceChangePasswordRequest(
  action: PayloadAction<{ user: CognitoUser; password: string; name: string }>,
) {
  const { user, password, name } = action.payload;
  try {
    if (!user) return;
    yield call([Auth, Auth.completeNewPassword], user, password, { name });
    yield put(
      actions.changePasswordSuccess({
        successFeedback: {
          title: setNewPassword.successTitle,
          subTitle: setNewPassword.successSubTitle,
        },
      }),
    );
  } catch (error) {
    const authError = new AuthErrorTransformer().transformOut(error);
    yield put(actions.changePasswordFailure({ errors: { message: en.somethingWentWrong } }));
  }
}

function* loginSuccess(action: PayloadAction<{ history: any }>) {
  //yield put(studySpaceActions.fetchAttempts();
  const localStorageParam = new LocalStorageWithTTL(JOB_APP_QUERY_PARAM);
  const storedJobID = localStorageParam.get();
  if (storedJobID) {
    action.payload.history.push(applyPath);
    localStorageParam.clear();
  }
  yield put(subscriptionActions.fetchSubscriptionRequest());
  yield put(subscriptionActions.fetchAddonsRequest());
}

function* editRoleRequest(action: PayloadAction<{ role: EUserRole; attemptId: string; questionIdOverride?: string }>) {
  try {
    const { role: rawRole, attemptId, questionIdOverride } = action.payload;
    const questionId = questionIdOverride || roleQuestionId;
    const role = new UserRolesTransformer().transformOut(rawRole);
    const questionResponseTransformer = new QuestionResponseTransformer();
    const transformedResponse = questionResponseTransformer.transformOut({ questionId, response: role }, true);
    const { data }: IAnswerQuestionResponse = yield call(axiosRequest, {
      url: endpoints.submitAnswer(attemptId),
      requestData: { body: transformedResponse },
      method: ERequestMethods.POST,
    });

    yield put(actions.editRoleSuccess({ role }));
  } catch (error) {
    yield put(actions.editRoleFailure());
  }
}

function* editRoleRequestV0(action: PayloadAction<{ role: EUserRole; questionIdOverride?: string }>) {
  try {
    const { role, questionIdOverride } = action.payload;
    const quizId = ROLE_PLACE_HOLDER_ID;
    const { data: result }: IStartQuizResponse = yield call(pollRequest, {
      url: endpoints.startQuiz,
      requestData: { body: { quizId } },
      method: ERequestMethods.POST,
    });
    const attemptId = result;
    yield editRoleRequest({ payload: { role: role, questionIdOverride, attemptId }, type: 'editRoleRequst' });
  } catch (error) {
    yield put(actions.editRoleFailure());
  }
}

function* watchResendConfirmationCode() {
  yield takeLatest(actions.resendConfirmationCodeRequest, resendConfirmationCode);
}

function* watchLogin() {
  yield takeLatest(actions.loginRequest, login);
}

function* watchFederatedLogin() {
  yield takeLatest(actions.federatedLoginRequest, federatedLogin);
}

function* watchSignup() {
  yield takeLatest(actions.signUpRequest, signup);
}

function* watchConfirmAccount() {
  yield takeLatest(actions.confirmAccountRequest, confirmAccount);
}

function* watchSendForgotPasswordCode() {
  yield takeLatest(actions.forgotPasswordRequest, sendPasswordResetCode);
}

function* watchChangePassword() {
  yield takeLatest(actions.changePasswordRequest, changePasswordRequest);
}

function* watchForceChangePassword() {
  yield takeLatest(actions.forceChangeNewPasswordRequest, watchForceChangePasswordRequest);
}

function* watchLoginSuccess() {
  yield takeLatest(actions.loginSuccess, loginSuccess);
}

function* watchFederatedLoginSuccess() {
  yield takeLatest(actions.federatedLoginSuccess, loginSuccess);
}

function* watchEditRoleRequest() {
  yield takeLatest(actions.editRoleRequest, editRoleRequest);
}

function* watchEditRoleRequestV0() {
  yield takeLatest(actions.editRoleRequestV0, editRoleRequestV0);
}

const sagas = [
  watchLogin,
  watchSignup,
  watchConfirmAccount,
  watchResendConfirmationCode,
  watchSendForgotPasswordCode,
  watchChangePassword,
  watchForceChangePassword,
  watchLoginSuccess,
  watchFederatedLogin,
  watchFederatedLoginSuccess,
  watchEditRoleRequest,
  watchEditRoleRequestV0,
];
export default sagas;
