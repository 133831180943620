import { useContext, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { JOB_APP_QUERY_PARAM, jobIdLabelMap } from './constants';
import { AppContext } from '../../AppContext';
import LocalStorageWithTTL from '../classes/LocalStorageWithTTL';
import { useAppDispatch } from '../redux/store';
import { actions, applySelector } from './redux/slice';
import { isNumeric } from '../utils';
import { useNavigations } from '../hooks';
import useAttempts from '../hooks/useAttempts';
import { useSelector } from 'react-redux';
import { EApplyStep, EApplyTypes } from '../types';
import { current } from '@reduxjs/toolkit';

const DAY_MS = 8.64e7;

const useJobApplicationInit = () => {
  const { isLoggedIn } = useContext(AppContext);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const localStorageHandler = new LocalStorageWithTTL(JOB_APP_QUERY_PARAM, DAY_MS);
  const { jobAppId, jobAppsFetched, applyQuizFetched, currentStep } = useSelector(applySelector.allState);
  const { navigateToRegistration, navigateToApply, navigateToJobSubmitted } = useNavigations();
  const storedJobAppId = searchParams.get(JOB_APP_QUERY_PARAM) || localStorageHandler.get() || jobAppId;
  const dispatch = useAppDispatch();
  const { currentApplyAttempt } = useAttempts();
  const isNavigated = useRef(false);

  const isValidJobAppId = (id: number | string) => {
    return !!jobIdLabelMap[id as keyof typeof jobIdLabelMap];
  };

  useEffect(() => {
    const jobAppId = searchParams.get(JOB_APP_QUERY_PARAM);
    if (!jobAppId || !isNumeric(jobAppId)) return;
    if (isValidJobAppId(+jobAppId)) localStorageHandler.set(+jobAppId);
  }, []);

  useEffect(() => {
    const baseConditionBreak =
      !storedJobAppId ||
      !isValidJobAppId(+storedJobAppId) ||
      isNavigated.current ||
      currentStep === EApplyStep.QUIZ_STARTED;

    if (!isLoggedIn) {
      if (baseConditionBreak) return;
      dispatch(actions.setJobAppId({ jobId: +storedJobAppId }));
      const text = getJobSignUpText(+storedJobAppId);
      navigateToRegistration(text);
      return;
    }

    if (isLoggedIn) {
      if (baseConditionBreak || !jobAppsFetched || !applyQuizFetched || isNavigated.current) return;
      isNavigated.current = true;
      if (currentApplyAttempt) {
        dispatch(actions.resetJobAppID());
        localStorageHandler.clear();
        if (currentApplyAttempt.isDone) navigateToJobSubmitted();
        else dispatch(actions.setCurrentType({ type: EApplyTypes.JOB_APP }));
        return;
      }

      dispatch(actions.setJobAppId({ jobId: +storedJobAppId }));
      localStorageHandler.clear();
      navigateToApply();
    }
  }, [storedJobAppId, currentApplyAttempt, isLoggedIn, jobAppsFetched, applyQuizFetched]);

  const getJobSignUpText = (jobId: number) => {
    const jobName = jobIdLabelMap[jobId as keyof typeof jobIdLabelMap];
    return jobName;
  };

  return { storedJobAppId };
};

export default useJobApplicationInit;
