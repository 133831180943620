import { MessageContainer, TVariant } from './styled';
import { en } from '../../../i18n';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import DoneIcon from '@mui/icons-material/Done';

import { ReactComponent as ErrorIcon } from '../../../images/icons/errorMessage.svg';
import React from 'react';

const { components } = en;

interface IMessage {
  children?: React.ReactNode;
  variant?: TVariant;
  Icon?: React.ElementType<any>;
}

const Message = ({ children, variant = 'warning', Icon: IconOverride }: IMessage) => {
  const getIcon = () => {
    if (IconOverride) return IconOverride;
    switch (variant) {
      case 'warning':
        return WarningAmberIcon;
      case 'success':
        return DoneIcon;
      // case 'info':
      //   return InfoIcon;
      default:
        return ErrorIcon;
    }
  };
  const Icon = getIcon();
  return (
    <MessageContainer className="message-container" variant={variant}>
      <Icon />
      {children || <span>{components.noContentFound.defaultMessage}</span>}
    </MessageContainer>
  );
};

export default Message;
