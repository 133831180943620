import { call, put, takeLatest } from 'redux-saga/effects';
import { actions } from './slice';
import axiosRequest, { ERequestMethods } from '../../axios';
import {
  IFetchAttemptResponse,
  IAttempt,
  IStartQuizResponse,
  ILooseObject,
  TApplyResponse,
  IAnswerQuestionResponse,
  EApplyTypes,
  EApplyNewQuiz,
} from '../../types';
import { endpoints } from '../../constants';
import {
  QuestionResponseTransformer,
  FullAttemptsTransformer,
  GoogleTags,
  AllAttemptsTransformer,
} from '../../classes';
import { pollRequest } from '../../utils';
import { applyEnhancementQuizIds, applyjobAppQuizIds, applyQuizIds } from '../../StudySpace/staticConstants';
import ApplyAnswersTransformer from '../../classes/DataTransformers/ApplyAnswersTransformer';
import { v1 as uuidv1 } from 'uuid';
import { APPLY_JOB_ID_QUESTION, JOB_APP_QUIZ_ID } from '../constants';
import { ActionCreatorWithPayload } from '@reduxjs/toolkit';

function* attemptQuiz(action: { payload: { quizId: string; isEnhance?: boolean; jobId?: number } }) {
  try {
    const { quizId, isEnhance, jobId } = action.payload;
    switch (true) {
      case !!jobId:
        yield put(actions.setNewQuizType({ quizType: EApplyNewQuiz.JOB_APP }));
        break;
      case !!isEnhance:
        yield put(actions.setNewQuizType({ quizType: EApplyNewQuiz.ENHANCEMENT }));
        break;
      default:
        yield put(actions.setNewQuizType({ quizType: EApplyNewQuiz.BASE }));
    }
    const { data: result }: IStartQuizResponse = yield call(pollRequest, {
      url: endpoints.startQuiz,
      requestData: { body: { quizId } },
      method: ERequestMethods.POST,
    });
    const attemptId = result;
    if (!attemptId) throw new Error('Could not start quiz');
    const stoppingCriteria = (apiAttempt: any) => {
      return apiAttempt.data.attemptId === attemptId;
    };
    const { data: attempt }: IFetchAttemptResponse = yield call(
      pollRequest,
      {
        url: endpoints.fetchAttempt(attemptId),
      },
      { stopCallBack: stoppingCriteria },
    );
    const attemptsTransformer = new FullAttemptsTransformer();
    const transformedAttempt: IAttempt = yield call([attemptsTransformer, attemptsTransformer.transform], attempt);
    const allAttempsTransformer = new AllAttemptsTransformer({ jobId });
    const fullTransformedAttempts: IAttempt[] = yield call(
      [allAttempsTransformer, allAttempsTransformer.transform],
      [transformedAttempt],
    );

    switch (true) {
      case !!jobId:
        yield put(actions.fetchApplyJobAppsSuccess({ attempts: fullTransformedAttempts }));
        yield put(
          actions.submitAnswer({
            attemptId,
            questionId: APPLY_JOB_ID_QUESTION,
            response: `${jobId}`,
            noIndexInc: true,
            type: EApplyTypes.JOB_APP,
          }),
        );
        break;
      case isEnhance:
        yield put(actions.fetchApplyEnhancementsSuccess({ attempts: fullTransformedAttempts }));
        yield put(actions.applyEnhancementsStarted({ currentIndex: 0 }));
        break;
      default:
        yield put(actions.fetchApplyAttemptsSuccess({ attempts: fullTransformedAttempts }));
    }

    yield put(actions.attemptQuizSuccess({}));
  } catch (e) {
    yield put(actions.attemptQuizFailure({ errors: { message: e } }));
  }
}

function* fetchApplyAttempts() {
  try {
    let attempts: IAttempt[] = [];
    yield call(function* () {
      for (const quizId of applyQuizIds) {
        const { data } = yield call(axiosRequest, { url: `${endpoints.fetchAttempts}&quizId=${quizId}` });
        attempts = data.concat(attempts);
      }
    });
    attempts = attempts.sort((a, b) => new Date(b.ctime).getTime() - new Date(a.ctime).getTime());

    const allAttemptsTransformer = new AllAttemptsTransformer();
    const attemptsTransformer = new FullAttemptsTransformer();
    const transformedAttempts = allAttemptsTransformer.transform(attempts);
    const currentApplyAttempt =
      transformedAttempts?.find(attempt => attempt.isDone) ||
      //transformedAttempts?.find(attempt => isFirstOnboardingPartSolved(attempt)) ||
      transformedAttempts?.[0];

    if (!currentApplyAttempt) {
      yield put(actions.fetchApplyAttemptsSuccess({ attempts: [] }));
      return;
    }
    const transformedAttempt: IAttempt = yield call(
      [attemptsTransformer, attemptsTransformer.transform],
      currentApplyAttempt,
    );

    const answers = new ApplyAnswersTransformer().transform(transformedAttempt);

    yield put(actions.setQuestionAnswers({ questionAnswers: answers }));
    yield put(actions.fetchApplyAttemptsSuccess({ attempts: [transformedAttempt] }));
  } catch (error) {
    yield put(actions.fetchApplyAttemptsFailure());
  }
}

function* fetchApplyEnhancementsAttempts() {
  try {
    let attempts: IAttempt[] = [];
    yield call(function* () {
      for (const quizId of applyEnhancementQuizIds) {
        const { data } = yield call(axiosRequest, { url: `${endpoints.fetchAttempts}&quizId=${quizId}` });
        attempts = data.concat(attempts);
      }
    });
    attempts = attempts.sort((a, b) => new Date(b.ctime).getTime() - new Date(a.ctime).getTime());

    const allAttemptsTransformer = new AllAttemptsTransformer();
    const attemptsTransformer = new FullAttemptsTransformer();
    const transformedAttempts = allAttemptsTransformer.transform(attempts);
    const currentApplyAttempt =
      transformedAttempts?.find(attempt => attempt.isDone) ||
      //transformedAttempts?.find(attempt => isFirstOnboardingPartSolved(attempt)) ||
      transformedAttempts?.[0];
    if (!currentApplyAttempt) {
      yield put(actions.fetchApplyEnhancementsSuccess({ attempts: [] }));
      return;
    }
    const transformedAttempt: IAttempt = yield call(
      [attemptsTransformer, attemptsTransformer.transform],
      currentApplyAttempt,
    );

    const answers = new ApplyAnswersTransformer().transform(transformedAttempt);

    yield put(actions.setQuestionAnswers({ questionAnswers: answers }));
    yield put(actions.fetchApplyEnhancementsSuccess({ attempts: [transformedAttempt] }));
  } catch (error) {
    yield put(actions.fetchApplyEnhancementsFailure());
  }
}

function* fetchApplyJobAppAttempts(action: { payload: { jobId?: number } }) {
  try {
    let attempts: IAttempt[] = [];
    yield call(function* () {
      for (const quizId of applyjobAppQuizIds) {
        const { data } = yield call(axiosRequest, { url: `${endpoints.fetchAttempts}&quizId=${quizId}&pageSize=90` });
        attempts = data.concat(attempts);
      }
    });

    const { jobId } = action.payload;
    attempts = attempts.sort((a, b) => new Date(b.ctime).getTime() - new Date(a.ctime).getTime());

    const allAttemptsTransformer = new AllAttemptsTransformer();
    const attemptsTransformer = new FullAttemptsTransformer();
    const transformedAttempts = allAttemptsTransformer.transform(attempts);

    const getCurrentAttempt = () => {
      if (jobId) {
        const jobIdAttempts = transformedAttempts.filter(attempt => attempt.jobId === `${jobId}`);
        if (jobIdAttempts.length > 0) return jobIdAttempts[0];
      }
      return transformedAttempts?.find(attempt => attempt.isDone) || transformedAttempts?.[0];
    };

    const currentApplyAttempt = getCurrentAttempt();
    if (!currentApplyAttempt) {
      yield put(actions.fetchApplyJobAppsSuccess({ attempts: [] }));
      return;
    }
    const transformedAttempt: IAttempt = yield call(
      [attemptsTransformer, attemptsTransformer.transform],
      currentApplyAttempt,
    );

    const rest = transformedAttempts.filter(attempt => attempt.attemptId !== currentApplyAttempt.attemptId);

    const answers = new ApplyAnswersTransformer().transform(transformedAttempt);

    yield put(actions.setQuestionAnswers({ questionAnswers: answers }));
    yield put(actions.fetchApplyJobAppsSuccess({ attempts: [transformedAttempt, ...rest] }));
  } catch (error) {
    yield put(actions.fetchApplyJobAppsFailure());
  }
}

function* transformAttempt(action: { payload: { attempt: IAttempt } }) {
  try {
    const { attempt } = action.payload;

    const attemptsTransformer = new FullAttemptsTransformer();
    const transformedAttempt: IAttempt = yield call([attemptsTransformer, attemptsTransformer.transform], attempt);
    const { questionsTransformed } = transformedAttempt;
    yield put(actions.transformAttemptSuccess({ questions: questionsTransformed }));
    yield put(actions.fetchApplyAttemptsSuccess({ attempts: [transformedAttempt] }));
  } catch (e) {
    yield put(actions.transformAttemptFailure({ errors: { message: e } }));
  }
}

function* submitAnswer(action: {
  payload: {
    attemptId: string;
    questionId: string;
    response: TApplyResponse | TApplyResponse[];
    noSucessSideEffects?: boolean;
    noIndexInc?: boolean;
    type: EApplyTypes;
  };
}) {
  const questionResponseTransformer = new QuestionResponseTransformer();
  try {
    const { payload } = action;
    const { questionId, response, noSucessSideEffects, noIndexInc, type } = payload;

    const transformedResponse = questionResponseTransformer.stringify({
      questionId,
      response: response as ILooseObject,
    });

    const { data }: IAnswerQuestionResponse = yield call(axiosRequest, {
      url: endpoints.submitAnswer(payload.attemptId),
      requestData: { body: transformedResponse },
      method: ERequestMethods.POST,
    });

    const baseActions = {
      submitAnswerSuccessNoSideEffects: actions.submitAnswerSuccessNoSideEffects,
      submitAnswerSuccessNoIndexInc: actions.submitAnswerSuccessNoIndexInc,
      submitAnswerSuccess: actions.submitAnswerSuccess,
    };

    const jobAppActions = {
      submitAnswerSuccessNoSideEffects: actions.submitAnswerSuccessNoSideEffectsJobApp,
      submitAnswerSuccessNoIndexInc: actions.submitAnswerSuccessNoIndexIncJobApp,
      submitAnswerSuccess: actions.submitJobAppAnswerSuccess,
    };

    const quizActions = type === EApplyTypes.JOB_APP ? jobAppActions : baseActions;

    switch (true) {
      case noSucessSideEffects:
        yield put(quizActions.submitAnswerSuccessNoSideEffects({ data: {}, questionId: payload.questionId }));
        break;
      case noIndexInc:
        yield put(quizActions.submitAnswerSuccessNoIndexInc({ data: {}, questionId: payload.questionId }));
        break;
      default:
        yield put(quizActions.submitAnswerSuccess({ data: {}, questionId: payload.questionId }));
    }
  } catch (error) {
    yield put(actions.submitAnswerFailure({ errors: {} }));
  }
}

function* upload(action: {
  payload: { questionId: string; file: File; attemptId: string; noSucessSideEffects?: boolean; type: EApplyTypes };
}) {
  try {
    const { file, questionId, attemptId, noSucessSideEffects, type } = action.payload;
    const { data }: ILooseObject = yield call(axiosRequest, {
      url: endpoints.upload,
      requestData: { body: { title: 'My Resume' } },
      method: ERequestMethods.POST,
    });

    const { url = '', fields = {} } = data || {};
    const formData = new FormData();
    Object.entries(fields).forEach(([field, value]) => formData.append(field, `${value}`));
    formData.append('Content-Type', file?.type);
    formData.append('file', file);

    const { data: uploadResult } = yield call(axiosRequest, {
      url,
      isJson: false,
      requestData: { body: formData },
      method: ERequestMethods.POST,
      useAuthorization: false,
    });

    const answerPayload = {
      uuid: fields.key,
      name: file.name,
    };

    yield put(
      actions.submitAnswer({
        attemptId,
        questionId,
        response: answerPayload,
        noSucessSideEffects,
        type,
      }),
    );

    yield put(
      actions.uploadSuccess({
        questionId,
        ...answerPayload,
      }),
    );
  } catch (error) {
    yield put(actions.uploadFailure());
  }
}

function* watchTransformAttempt() {
  yield takeLatest(actions.transformAttemptRequest, transformAttempt);
}

function* watchAttemptQuiz() {
  yield takeLatest(actions.attemptQuiz, attemptQuiz);
}

function* watchSubmitAnswer() {
  yield takeLatest(actions.submitAnswer, submitAnswer);
}
function* watchFetchApplyAttempts() {
  yield takeLatest(actions.fetchApplyAttempts, fetchApplyAttempts);
}

function* watchUploadRequest() {
  yield takeLatest(actions.uploadRequest, upload);
}

function* watchFetchApplyEnhanceAttempts() {
  yield takeLatest(actions.fetchApplyEnahcnementAttempts, fetchApplyEnhancementsAttempts);
}

function* watchFetchJobAppsAttempts() {
  yield takeLatest(actions.fetchApplyJobAppsAttempts, fetchApplyJobAppAttempts);
}

const sagas = [
  watchTransformAttempt,
  watchAttemptQuiz,
  watchSubmitAnswer,
  watchFetchApplyAttempts,
  watchUploadRequest,
  watchFetchApplyEnhanceAttempts,
  watchFetchJobAppsAttempts,
];

export default sagas;
