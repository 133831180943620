import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  IQuestion,
  ILooseObject,
  IAttempt,
  IApplyState,
  TApplyResponse,
  EApplyTypes,
  EApplyStep,
  EApplyNewQuiz,
} from '../../types';
import { RootState } from '../../redux/store';
const SLICE_NAME = 'apply';

const initialState: IApplyState = {
  loading: true,
  errors: {},
  questionAnswers: {},
  loadingAnswer: false,
  attempts: [],
  enhancementsAttempts: [],
  loadingStartQuiz: false,
  currentQuestionIndex: 0,
  loadingFetchEnhance: false,
  loadingFetchJobApps: false,
  currentType: EApplyTypes.BASE,
  jobAppAttempts: [],
  applyQuizFetched: false,
  applyEnhanceQuizFetched: false,
  currentStep: EApplyStep.BASE,
  newQuiz: EApplyNewQuiz.NONE,
};

const slice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    attemptQuiz: (state, action: PayloadAction<{ quizId: string; isEnhance?: boolean; jobId?: number }>) => {
      state.loadingStartQuiz = true;
      state.currentStep = EApplyStep.QUIZ_STARTED;
    },

    attemptQuizSuccess: (state, action: PayloadAction<{}>) => {
      state.loadingStartQuiz = false;
    },
    attemptQuizFailure: (state, action: PayloadAction<{ errors: ILooseObject }>) => {
      state.loadingStartQuiz = false;
      state.errors = action.payload.errors;
    },

    transformAttemptRequest: (state, action: PayloadAction<{ attempt: IAttempt }>) => {
      state.loading = true;
    },
    transformAttemptSuccess: (state, action: PayloadAction<{ questions: { [key: string]: IQuestion } }>) => {
      state.questionsTransformed = action.payload.questions;
      state.loading = false;
    },

    transformAttemptFailure: (state, action: PayloadAction<{ errors: ILooseObject }>) => {
      state.errors = action.payload.errors;
      state.loading = false;
    },
    submitAnswer: (
      state,
      action: PayloadAction<{
        attemptId: string;
        questionId: string;
        response: TApplyResponse | TApplyResponse[];
        noSucessSideEffects?: boolean;
        noIndexInc?: boolean;
        type: EApplyTypes;
      }>,
    ) => {
      state.loadingAnswer = true;
    },

    submitAnswerSuccessNoIndexInc: (state, action: PayloadAction<any>) => {
      const { questionId } = action.payload;
      state.questionAnswers = {
        ...state.questionAnswers,
        [questionId]: { ...state.questionAnswers[questionId], isDone: true },
      };
      state.loadingAnswer = false;
    },

    submitAnswerSuccessNoSideEffects: (state, action: PayloadAction<any>) => {
      const { questionId } = action.payload;
      state.questionAnswers = {
        ...state.questionAnswers,
        [questionId]: { ...state.questionAnswers[questionId], isDone: true },
      };
    },

    submitAnswerSuccessNoIndexIncJobApp: (state, action: PayloadAction<any>) => {
      const { questionId } = action.payload;
      state.questionAnswers = {
        ...state.questionAnswers,
        [questionId]: { ...state.questionAnswers[questionId], isDone: true },
      };
      state.loadingAnswer = false;
    },

    submitAnswerSuccessNoSideEffectsJobApp: (state, action: PayloadAction<any>) => {
      const { questionId } = action.payload;
      state.questionAnswers = {
        ...state.questionAnswers,
        [questionId]: { ...state.questionAnswers[questionId], isDone: true },
      };
    },

    submitAnswerSuccess: (state, action: PayloadAction<any>) => {
      const { questionId } = action.payload;
      state.questionAnswers = {
        ...state.questionAnswers,
        [questionId]: { ...state.questionAnswers[questionId], isDone: true },
      };
      state.loadingAnswer = false;
      state.currentQuestionIndex += 1;
    },
    submitJobAppAnswerSuccess: (state, action: PayloadAction<any>) => {
      const { questionId } = action.payload;
      state.questionAnswers = {
        ...state.questionAnswers,
        [questionId]: { ...state.questionAnswers[questionId], isDone: true },
      };
      state.loadingAnswer = false;
      state.currentQuestionIndex += 1;
    },
    setQuestionAnswer: (state, action: PayloadAction<{ questionId: string; response: TApplyResponse }>) => {
      const { questionId, response } = action.payload;
      state.questionAnswers = { ...state.questionAnswers, [questionId]: { response, isDone: false } };
    },

    setQuestionAnswers: (state, action: PayloadAction<{ questionAnswers: ILooseObject }>) => {
      state.questionAnswers = { ...state.questionAnswers, ...action.payload.questionAnswers };
    },

    submitAnswerFailure: (state, action: PayloadAction<{ errors: ILooseObject }>) => {
      state.errors = action.payload.errors;
      state.loading = false;
    },

    fetchApplyAttempts: state => {
      state.loading = true;
    },

    fetchApplyAttemptsSuccess: (state, action: PayloadAction<{ attempts: IAttempt[] }>) => {
      state.applyQuizFetched = true;
      const { attempts } = action.payload;
      state.attempts = attempts;
      state.loading = false;
    },

    applyEnhancementsStarted: (state, action: PayloadAction<{ currentIndex: number }>) => {
      state.currentType = EApplyTypes.ENHANCE;
      state.currentQuestionIndex = action.payload.currentIndex;
    },

    fetchApplyEnahcnementAttempts: state => {
      state.loadingFetchEnhance = true;
    },

    fetchApplyEnhancementsSuccess: (state, action: PayloadAction<{ attempts: IAttempt[] }>) => {
      const { attempts } = action.payload;
      state.enhancementsAttempts = attempts;
      state.loadingFetchEnhance = false;
      state.applyEnhanceQuizFetched = true;
    },

    fetchApplyEnhancementsFailure: state => {
      state.loadingFetchEnhance = false;
      state.applyEnhanceQuizFetched = true;
    },

    fetchApplyAttemptsFailure: state => {
      state.loading = false;
    },

    changeQuestionDoneStatus: (state, action: PayloadAction<{ questionId: string; isDone: boolean }>) => {
      const { questionId, isDone } = action.payload;
      state.questionAnswers[questionId].isDone = isDone;
    },

    decrementQuestionIndex: state => {
      state.currentQuestionIndex -= 1;
    },

    incrementQuestionIndex: state => {
      state.currentQuestionIndex += 1;
    },
    setCurrentQuestionIndex: (state, action: PayloadAction<{ index: number }>) => {
      state.currentQuestionIndex = action.payload.index;
    },

    uploadRequest: (
      state,
      action: PayloadAction<{
        questionId: string;
        file: File;
        attemptId: string;
        noSuccessSideEffects?: boolean;
        type: EApplyTypes;
      }>,
    ) => {
      state.loadingAnswer = true;
    },

    uploadFailure: state => {
      state.loadingAnswer = false;
      state.currentQuestionIndex += 1; //Handle UploadError here
    },

    uploadSuccess: (state, action: PayloadAction<{ questionId: string; uuid: string; name: string }>) => {
      const { uuid, questionId, name } = action.payload;
      state.questionAnswers[questionId] = {
        response: {
          uuid,
          name,
        },
        isDone: true,
      };
    },

    setCurrentType: (state, action: PayloadAction<{ type: EApplyTypes }>) => {
      state.currentType = action.payload.type;
    },
    resetState: state => initialState,
    setJobAppId: (state, action: PayloadAction<{ jobId: number }>) => {
      state.jobAppId = action.payload.jobId;
      state.currentType = EApplyTypes.JOB_APP;
    },

    fetchApplyJobAppsAttempts: (state, action: PayloadAction<{ jobId?: number }>) => {
      state.loadingFetchJobApps = true;
    },

    fetchApplyJobAppsSuccess: (state, action: PayloadAction<{ attempts: IAttempt[] }>) => {
      const { attempts } = action.payload;
      state.jobAppAttempts = attempts;
      state.loadingFetchJobApps = false;
      state.jobAppsFetched = true;
    },

    fetchApplyJobAppsFailure: state => {
      state.loadingFetchJobApps = false;
      state.jobAppsFetched = true;
    },

    setCurrentStep: (state, action: PayloadAction<{ step: EApplyStep }>) => {
      state.currentStep = action.payload.step;
    },

    setNewQuizType: (state, action: PayloadAction<{ quizType: EApplyNewQuiz }>) => {
      state.newQuiz = action.payload.quizType;
    },

    resetJobAppID: state => {
      state.jobAppId = undefined;
      state.currentType = EApplyTypes.BASE;
    },
  },
});

const applySelector = {
  allState: (state: RootState): typeof initialState => state.apply,
  endScreen: (state: RootState): boolean => state.apply.currentQuestionIndex === -1,
  unstarted: (state: RootState): boolean => {
    if (state.apply.currentStep === EApplyStep.IN_PROGRESS) return true;

    return state.apply.attempts.length === 0 && state.apply.jobAppAttempts.length === 0;
  },
};

const actions = { ...slice.actions };

export { applySelector, SLICE_NAME, actions };
export default slice.reducer;
