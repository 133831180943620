import parse from 'html-react-parser';
import { useMediaQuery, useTheme } from '@mui/material';
import {
  UnstartedContainer,
  UnstartedTitle,
  UnstartedSubtitle,
  UnstartedBulletPoint,
  UnstartedFooter,
  UnstartedButtonOverrid,
} from './styled';
import { en } from '../../i18n';
import { ReactComponent as DrawIcon } from '../../images/icons/apply/draw.svg';
import { ReactComponent as FeedbackIcon } from '../../images/icons/apply/feedback.svg';
import { ReactComponent as FundamentalsIcon } from '../../images/icons/apply/fundamentals.svg';
import { ReactComponent as CollaborateIcon } from '../../images/icons/apply/collaborate.svg';
import { ReactComponent as ReviewIcon } from '../../images/icons/apply/review.svg';
import { ReactComponent as PlusIcon } from '../../images/plus.svg';
import ApplyBackground from '../../images/four-nurses.png';

const {
  apply: { unstarted },
} = en;

const icons: {
  [key: string]: JSX.Element;
} = {
  draw: <DrawIcon />,
  feedback: <FeedbackIcon />,
  fundamentals: <FundamentalsIcon />,
  collaborate: <CollaborateIcon />,
  review: <ReviewIcon />,
};

interface IUnstarted {
  startQuiz: () => void;
  loadingStartQuiz: boolean;
}

const Unstarted = ({ startQuiz, loadingStartQuiz }: IUnstarted) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <UnstartedContainer>
      <div>
        <UnstartedTitle>{unstarted.title}</UnstartedTitle>
        <UnstartedSubtitle>{unstarted.subtitle}</UnstartedSubtitle>
        {unstarted.bulletPoints.map(point => (
          <UnstartedBulletPoint>
            {icons[point.icon]}
            {parse(point.text)}
          </UnstartedBulletPoint>
        ))}
        <UnstartedFooter>{unstarted.footer}</UnstartedFooter>
        <UnstartedButtonOverrid
          loading={loadingStartQuiz}
          onClick={startQuiz}
          size="large"
          variant="primary"
          fullWidth={isMobile}
        >
          <PlusIcon /> {unstarted.button}
        </UnstartedButtonOverrid>
      </div>
      <img src={ApplyBackground} />
    </UnstartedContainer>
  );
};

export default Unstarted;
