import Button from '../../components/Button';
import {
  ErrorMessageContent,
  PaymentIssueMessageContent,
  PaymentIssueText,
} from '../../Membership/LandingSubscribed/styled';
import { en } from '../../../i18n';
import Message from '../../components/Message';
import { ErrorMessageContentOverride } from './styled';
import NotificationsOffOutlinedIcon from '@mui/icons-material/NotificationsOffOutlined';
import { useSelector } from 'react-redux';
import { actions, applySelector } from '../redux/slice';
import { useDispatch } from '../../hooks';
import { NOTIFICATIONS_ID, PROFILE_MATCHES_ID, SIMILAR_ROLES_ID } from '../constants';
import useStartApplyEnhance from '../useStartApplyEnhance';
import { EApplyTypes, EAttemptTypes } from '../../types';
import { questionData } from '../useSteps';
const { apply } = en;
const {
  exit: { jobs },
} = apply;

const ErrorMessage = () => {
  const { loadingAnswer } = useSelector(applySelector.allState);
  const { currentApplyAttempt } = useStartApplyEnhance();
  const { dispatch } = useDispatch();
  const turnOn = () => {
    if (currentApplyAttempt?.quizType !== EAttemptTypes.APPLY_JOB_APPS) return;
    const response = [NOTIFICATIONS_ID, PROFILE_MATCHES_ID, SIMILAR_ROLES_ID];
    dispatch(
      actions.setQuestionAnswer({
        questionId: questionData.optIn.id,
        response,
      }),
    );
    dispatch(
      actions.submitAnswer({
        noIndexInc: true,
        attemptId: currentApplyAttempt.attemptId,
        questionId: questionData.optIn.id,
        response,
        type: EApplyTypes.JOB_APP,
      }),
    );
  };
  return (
    <Message Icon={NotificationsOffOutlinedIcon} key="matching-disabled" variant="errorRed300">
      <ErrorMessageContentOverride>
        <PaymentIssueText>{jobs.matchingDisabledError}</PaymentIssueText>
        <Button onClick={turnOn} loading={loadingAnswer} size="small" variant="errorRed300">
          {jobs.matchingDisabledButton}
        </Button>
      </ErrorMessageContentOverride>
    </Message>
  );
};

export default ErrorMessage;
