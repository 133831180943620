import { handleProdConfig } from '../../resources/configObject';
handleProdConfig();

const entities = {
  topics: {
    name: 'topics',
  },
  quiz: {
    name: 'quiz',
  },
  resources: {
    name: 'resources',
  },
  uploads: {
    name: 'uploads',
  },
};

const quizzingEndpoint = `${window.config?.quizzingEndpoint}` || 'https://quizzing.api.smartn.ca';
const billingEndpoint = `${window.config?.billingEndpoint}` || 'https://billing.api.smartn.ca';
const nurseGPTEndpoint = `${window.config?.nurseGPTEndpoint}` || 'https://ask-v3.api.smartn.ca';
const nurseGPTWebSocketEndpoint = `${window.config?.nurseGPTWebSocketEndpoint}` || 'wss://ask-ws.api.smartn.ca';
const myContentEndpoint = `${window.config?.endpointV2}` || 'https://my-content.api.smartn.ca';
const webSessionAuthEndpoint = `${window.config?.webSessionAuthEndpoint}` || '';
const publicNurseGPTWebSocketEndpoint = `${window.config?.publicNurseGPTWebSocketEndpoint}` || '';

const endpoints = {
  fetchTopics: (subjectId: string) => `/groups/${subjectId}/subgroupings`,
  createQuiz: '/quizzes',
  startQuiz: `${quizzingEndpoint}/attempts`,
  fetchAttempt: (id: string) => `${quizzingEndpoint}/attempts/${id}`,
  fetchQuestion: (questionId: string) => `/videos/${questionId}/media`,
  submitAnswer: (attemptId: string) => `${quizzingEndpoint}/attempts/${attemptId}/responses`,
  fetchAttempts: `${quizzingEndpoint}/attempts?sort=desc`,
  fetchResources: (subjectId: string) => `/groups/${subjectId}/shares?q=study-material`,
  fetchUploads: '/videos?q=study-material-uploads&sort=desc',
  upload: `${myContentEndpoint}/videos`,
  fetchGroups: '/groups?q=roots',
  fetchMediaLink: (resourceId: string) => `/videos/${resourceId}/media`,
  sendEvent: '/events',
  subscriptions: `${billingEndpoint}/subscriptions`,
  subscriptionId: (id: string) => `${billingEndpoint}/subscriptions/${id}`,
  promocodes: (code: string, productId: string) => `${billingEndpoint}/promocodes/${code}?productID=${productId}`,
  addresses: `${billingEndpoint}/addresses`,
  paymentMethods: `${billingEndpoint}/paymentmethods`,
  paymentIntents: `${billingEndpoint}/paymentintents`,
  nurseGPT: `${nurseGPTEndpoint}/ask-smartn`,
  nurseGPTWebSocket: nurseGPTWebSocketEndpoint,
  publicNurseGPTWebSocket: publicNurseGPTWebSocketEndpoint,
  webSessionAuth: `${webSessionAuthEndpoint}/sessions`,
};

[entities, endpoints].forEach(object => Object.freeze(object));

const POLLING_DELAY = 550;
const POLLING_ATTEMPTS = 12;

const IS_SIGN_UP_LOCAL_STORAGE_ID = 'isSignUp';
const TRIAL_ENDED_MODAL_SHOWN = 'trialEndedModalShown';
const READINESS_PASSING_CHANCE_QUESTION_THRESHOLD = 30;
const SINGLE_CHOICE_SELECT_ALL_QUESTIONS = ['1604d060-61e6-11ed-b235-81b172206403'];
const SINGLE_QUESTION_CASE_STUDIES = ['d027a1d8-ca06-11ed-afa1-0242ac120002', '0ac4d0a4-ca34-11ed-afa1-0242ac120002'];
const NURSE_GPT_QUIZ_ID = '2f10a930-b334-11ee-a506-0242ac120002';
const NURSE_GPT_QUESTION_ID = '96fa46d2-b334-11ee-a506-0242ac120002';
const MARKETING_WEBSITE_LINK = 'https://www.smartn.ca/';

export {
  entities,
  endpoints,
  POLLING_ATTEMPTS,
  POLLING_DELAY,
  IS_SIGN_UP_LOCAL_STORAGE_ID,
  TRIAL_ENDED_MODAL_SHOWN,
  READINESS_PASSING_CHANCE_QUESTION_THRESHOLD,
  SINGLE_CHOICE_SELECT_ALL_QUESTIONS,
  SINGLE_QUESTION_CASE_STUDIES,
  NURSE_GPT_QUIZ_ID,
  NURSE_GPT_QUESTION_ID,
  MARKETING_WEBSITE_LINK,
};
