import { styled } from '@mui/material/styles';
import { PageContainer } from '../../Auth/styled';
import { colors } from '../../../themes';
import { opacityInAnimation } from '../../sharedStyled';
import { TabContent } from '../../Home/Components/styled';
import Button from '../../components/Button';
import { ErrorMessageContent } from '../../Membership/LandingSubscribed/styled';
import { TopicBox, TopicsTitle } from '../../Home/Analyze/styled';

const ExitPageContainer = styled(PageContainer)`
  background-color: ${colors.lightGrey};

  min-height: calc(100vh - 64px);
  ${opacityInAnimation()};
`;

const ContentContainer = styled('div')`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  width: min(80%, 880px);
  margin: auto;
  ${props => props.theme.breakpoints.down('sm')} {
    width: 85%;
  }
  .message-container {
    width: 100%;
    margin: auto;
    box-sizing: border-box;
    ${props => props.theme.breakpoints.down('sm')} {
      flex-direction: column;
      align-items: flex-start;
      gap: 12px;
    }
  }
`;

const ExitCardsContainer = styled('div')`
  display: flex;
  margin-top: min(60px, 3.75rem);
  gap: 16px;
  justify-content: center;
  width: 100%;
  flex-direction: column;
  margin-bottom: 32px;
  &::first-child {
    width: 100%;
  }
`;

const FAQContainer = styled('div')`
  margin-top: 20px;
  margin-bottom: 48px;
  text-align: center;
`;

const Title = styled('div')`
  font-weight: 600;
  font-size: 1.5rem;
`;

const TabTitle = styled('h1')`
  margin: 0;
  color: ${colors.black10};
  display: flex;
  gap: 8px;
  margin-bottom: 24px;
  font-size: 1.25rem;
  font-weight: 700;
  margin-left: 1px;
`;

const JobsContainer = styled('div')`
  margin-top: min(32px, 2rem);
`;

const TabContentJobs = styled(TabContent)`
  color: ${colors.black};
`;

const JobsContentContainer = styled('div')`
  margin-top: 48px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const InvitesHeader = styled('div')`
  font-size: 1.5rem;
  front-weight: 600;
  margin-bottom: 32px;
  text-align: center;
`;

const InvitesSubHeader = styled('div')`
  font-size: 1rem;
  margin-bottom: 32px;
  text-align: center;
`;

const StyledButton = styled(Button)`
  margin-bottom: 24px;
  ${props => props.theme.breakpoints.down('$sm')} {
    width: 100%;
  }
`;

const InvitesHeaderDisabled = styled(InvitesHeader)`
  opacity: 38%;
`;

const ErrorMessageContentOverride = styled(ErrorMessageContent)`
  button {
    min-width: 120px;
  }
  ${props => props.theme.breakpoints.down('$sm')} {
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
  }
`;

const TableTitle = styled(TopicsTitle)`
  margin-left: 12px;
  margin-right: 12px;
  cursor: default;
`;

const RightTableTitle = styled(TableTitle)`
  margin-right: 72px;
  ${props => props.theme.breakpoints.down('$sm')} {
    margin-right: 55px;
  }
`;

const ApplicationCardContainer = styled(TopicBox)`
  ${props => props.theme.breakpoints.down('$sm')} {
    padding: 12px 12px;
  }
`;

const AttemptTitle = styled('div')`
  width: 60%;
`;

const ApplicationsContainer = styled('div')`
  display: flex;
  gap: 8px;
  flex-direction: column;
`;

const TableHeaderContainer = styled('div')`
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
  position: relative;
`;

const TableContainer = styled('div')`
  margin-bottom: 16px;
  width: 100%;
`;

const StatusTag = styled('div')`
  min-width: 104px;
  padding: 2px 15px;
  background: ${colors.lightGrey};
  color: ${colors.grey650};
  text-align: center;
  font-weight: 700;
  font-size: 0.875rem;
  border-radius: 4px;
  box-sizing: border-box;
  ${props => props.theme.breakpoints.down('$sm')} {
    min-width: 92px;
  }
`;

const IconHeaderContainer = styled('div')`
  ${props => props.theme.breakpoints.down('$sm')} {
    width: 100%;
  }
`;

export {
  ExitPageContainer,
  ContentContainer,
  ExitCardsContainer,
  FAQContainer,
  Title,
  TabTitle,
  JobsContainer,
  TabContentJobs,
  JobsContentContainer,
  InvitesHeader,
  InvitesSubHeader,
  StyledButton,
  InvitesHeaderDisabled,
  ErrorMessageContentOverride,
  TableTitle,
  ApplicationCardContainer,
  ApplicationsContainer,
  TableHeaderContainer,
  TableContainer,
  StatusTag,
  AttemptTitle,
  RightTableTitle,
};
