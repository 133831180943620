import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '../../redux/store';
import {
  IAuthState,
  IAuthError,
  ICognitoUser,
  ISuccessFeedbackPayload,
  CognitoUser,
  EAuthStates,
  EUserRole,
} from '../../types';

import { CurrentProfile } from '../../classes';
import HeaderBanner from '../../classes/Membership/HeaderBanner';

const initialState: IAuthState = {
  loading: false,
  errors: {},
  isError: false,
  user: CurrentProfile.loadProfile(),
  authState: CurrentProfile.loadProfile() ? EAuthStates.LOGGED_IN : null,
  loadingEditRole: false,
  headerBannerForceRender: 0,
};

const SLICE_NAME = 'auth';

type TAuthErrorAction = PayloadAction<{ errors: IAuthError }>;
type TSaveUserAction = PayloadAction<{ user: ICognitoUser; history: any }>;
type TSuccessFeedbackAction = PayloadAction<{ successFeedback: ISuccessFeedbackPayload }>;

const slice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    loginRequest: (state, action: PayloadAction<{ userName: string; password: string; history: any }>) => {
      state.loading = true;
      state.isError = false;
      state.successFeedback = undefined;
    },

    loginSuccess: (state, action: TSaveUserAction) => {
      state.loading = false;
      state.isError = false;
      state.user = action.payload.user;
      state.authState = EAuthStates.LOGGED_IN;
    },
    loginFailure: (state, action: TAuthErrorAction) => {
      state.loading = false;
      state.errors = action.payload.errors;
      state.isError = true;
    },

    federatedLoginRequest: state => {
      state.isError = false;
      state.successFeedback = undefined;
    },

    federatedLoginSuccess: (state, action: TSaveUserAction) => {
      state.isError = false;
      state.user = action.payload.user;
      state.authState = EAuthStates.LOGGED_IN;
    },

    federatedLoginFailure: (state, action: TAuthErrorAction) => {
      state.errors = action.payload.errors;
      state.isError = true;
    },

    signUpRequest: (
      state,
      action: PayloadAction<{
        userName: string;
        password: string;
        email: string;
        fullName: string;
        history: any;
      }>,
    ) => {
      state.loading = true;
      state.isError = false;
    },

    signUpSuccess: (state, action: TSaveUserAction) => {
      state.loading = false;
      state.isError = false;
      state.user = action.payload.user;
    },

    signUpFailure: (state, action: TAuthErrorAction) => {
      state.loading = false;
      state.errors = action.payload.errors;
      state.isError = true;
    },

    confirmAccountRequest: (
      state,
      action: PayloadAction<{
        userName: string;
        history: any;
        code: string;
      }>,
    ) => {
      state.loading = true;
      state.isError = false;
      state.successFeedback = undefined;
    },

    confirmAccountSuccess: (state, action: TSuccessFeedbackAction) => {
      state.loading = false;
      state.isError = false;
      state.successFeedback = action.payload.successFeedback;
    },

    ConfirmAccountFailure: (state, action: TAuthErrorAction) => {
      state.loading = false;
      state.errors = action.payload.errors;
      state.isError = true;
    },

    resendConfirmationCodeRequest: (state, action: PayloadAction<{ userName: string }>) => {
      state.loading = true;
      state.isError = false;
      state.successFeedback = undefined;
    },

    resendConfirmationCodeSuccess: (state, action: TSuccessFeedbackAction) => {
      state.loading = false;
      state.isError = false;
      state.successFeedback = action.payload.successFeedback;
    },
    resendConfirmationCodeFailure: (state, action: TAuthErrorAction) => {
      state.loading = false;
      state.errors = action.payload.errors;
      state.isError = true;
    },

    forgotPasswordRequest: (state, action: PayloadAction<{ email: string; history: any }>) => {
      state.loading = true;
      state.isError = false;
    },

    forgotPasswordSuccess: state => {
      state.loading = false;
      state.isError = false;
    },
    forgetPasswordFailure: (state, action: TAuthErrorAction) => {
      state.loading = false;
      state.errors = action.payload.errors;
      state.isError = true;
    },

    changePasswordRequest: (
      state,
      action: PayloadAction<{ email: string; code: string; password: string; history: any }>,
    ) => {
      state.loading = true;
      state.isError = false;
    },

    changePasswordSuccess: (state, action: TSuccessFeedbackAction) => {
      state.loading = false;
      state.isError = false;
      state.successFeedback = action.payload.successFeedback;
    },
    changePasswordFailure: (state, action: TAuthErrorAction) => {
      state.loading = false;
      state.errors = action.payload.errors;
      state.isError = true;
    },

    forceChangeNewPasswordRequest: (
      state,
      action: PayloadAction<{ user: CognitoUser; password: string; name: string }>,
    ) => {
      state.loading = true;
      state.isError = false;
    },

    resetState: () => ({ ...initialState, user: undefined, authState: null }),
    logOut: () => {
      return {
        loading: false,
        errors: {},
        isError: false,
        user: undefined,
        loadingEditRole: false,
        headerBannerForceRender: 0,
      };
    },

    refreshIdToken: (state, action: PayloadAction<{ idToken: string }>) => {
      const { user } = state;
      if (user) user.idToken = action.payload.idToken;
    },

    onboardingQuestionSuccess: state => {
      state.authState = EAuthStates.ONBOARDED;
    },

    setLiveOnboardingRole: (state, action: PayloadAction<{ role: string }>) => {
      state.liveOnboardingRole = action.payload.role;
    },

    editRoleRequest: (
      state,
      action: PayloadAction<{ role: EUserRole; attemptId: string; questionIdOverride?: string }>,
    ) => {
      state.loadingEditRole = true;
      HeaderBanner.resetRandIndex();
    },

    editRoleRequestV0: (state, action: PayloadAction<{ role: EUserRole; questionIdOverride?: string }>) => {
      state.loadingEditRole = true;
    },

    editRoleFailure: state => {
      state.loadingEditRole = false;
    },

    editRoleSuccess: (state, action: PayloadAction<{ role: string }>) => {
      state.loadingEditRole = false;
      state.liveOnboardingRole = action.payload.role;
    },

    forceRender: state => {
      state.headerBannerForceRender += 1;
    },
  },
});

const authSelectors = {
  allState: (state: RootState): typeof initialState => state.auth,
};

const actions = { ...slice.actions };
export { SLICE_NAME, actions, authSelectors };
export default slice.reducer;
