import { IQuestion } from '../types';
import { en } from '../../i18n';
import { Title, PreferredNameContentContainer } from './styled';
import { step } from './constants';
import parse from 'html-react-parser';
import { get } from 'lodash';
import { useMediaQuery, useTheme } from '@mui/material';

const { preferredNameDefaults } = en.onBoarding;

interface IPreferredName {
  currentStep: step;
  questions?: {
    [key: string]: IQuestion;
  };
  question?: IQuestion;
}

const PreferredName = ({ currentStep, questions, question }: IPreferredName) => {
  if (!(questions || question) || !currentStep.onboardingQuestionId) return null;
  const currentQuestion = questions?.[currentStep.onboardingQuestionId] || question;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const getPropsFromQuestion = () => {
    const props = {
      mainTitle: preferredNameDefaults.mainTitle,
      subTitle: preferredNameDefaults.subTitle,
      subTitleB: preferredNameDefaults.subTitleB,
      subTitleMobile: preferredNameDefaults.subTitleMobile,
    };
    if (!currentQuestion || !currentStep) return props;
    if (currentStep.titlePath) {
      const mainTitle = get(currentQuestion, currentStep.titlePath);
      if (mainTitle) {
        props.mainTitle = mainTitle;
      }
    }

    if (currentStep.subTitlePathB) {
      const subTitleB = get(currentQuestion, currentStep.subTitlePathB);
      if (subTitleB) {
        props.subTitleB = subTitleB;
      }
    }

    if (currentStep.subTitlePath) {
      const subTitle = get(currentQuestion, currentStep.subTitlePath);
      if (subTitle) {
        props.subTitle = subTitle;
      }
    }
    return props;
  };
  const props = getPropsFromQuestion();

  return (
    <PreferredNameContentContainer>
      <Title isLargeTitle>{props.mainTitle}</Title>
      <Title isLargeTitle> {parse(isMobile ? props.subTitleMobile : props.subTitleB)}</Title>
      <Title isLargeTitle>{parse(props.subTitle)}</Title>
    </PreferredNameContentContainer>
  );
};

export default PreferredName;
