import { AuthContainerOverride } from '../OnBoarding/styled';
import {
  BannerOverride,
  BannerTitle,
  FormPopupOveride,
  ProgressText,
  SmartQuizContainerOverride,
  QuestionContainer,
  RadialBackgroundOverride,
  LocationQuestionContainer,
  ExitSmartQuizContainer,
  ExitQuestionContainer,
  AuthContainerOverrideApply,
  SaveExitButton,
  PageContainerOverride,
  UnstartedPageContainerOverride,
} from './styled';
import { en } from '../../i18n';
import Unstarted from './Unstarted';
import { useDidMount, useNavigations, useSeoTags, useVariableKeyDownPress } from '../hooks';
import useApply from './useApply';
import Spinner from '../components/Spinner';
import { colors } from '../../themes';
import { questionData } from './useSteps';
import { MutableRefObject, useRef } from 'react';
import ProgressBar from '../components/ProgressBar';
import { Banner } from '../SmartQuiz/styled';
import parse from 'html-react-parser';
import Button from '../components/Button';
import CustomTooltip from '../components/Tooltip';
import { EApplyStep, EApplyTypes } from '../types';
import Progress from './Progress';

const { apply } = en;

const Apply = () => {
  const seo = useSeoTags({ pageName: 'apply' });
  const { state, control } = useApply({ fetchAttempts: true });
  const {
    loading,
    currentApplyAttempt,
    stepIndex,
    quizProgress,
    isNextDisabled,
    currentStep,
    loadingAnswer,
    isLastScreen,
    currentType,
    isDoneFetching,

    loadingStartQuiz,
  } = state;
  const { goNext, goPrevious, renderStep, save, startQuiz } = control;

  const bannerRef = useRef() as MutableRefObject<HTMLDivElement>;

  useVariableKeyDownPress('Enter', goNext);
  //useVariableKeyDownPress('Backspace', goPrevious);

  const renderNextButton = () => {
    const button = (
      <Button loading={loadingAnswer} disabled={isNextDisabled} onClick={goNext} variant="primary">
        {currentStep.buttonText || apply.next}
      </Button>
    );
    if (!isNextDisabled) return button;

    return (
      <CustomTooltip leftPopper="-30px" centerText width="100%" arrow={false} title={apply.answerRequired}>
        <span>{button}</span>
      </CustomTooltip>
    );
  };

  const renderExit = () => {
    return renderStep();
    // return (
    //   <ExitSmartQuizContainer>
    //     <ExitQuestionContainer>{renderStep()}</ExitQuestionContainer>
    //   </ExitSmartQuizContainer>
    // );
  };

  const renderTitle = () => {
    if (currentType === EApplyTypes.JOB_APP) return `${en.apply.jobApp.roleTitle}${currentApplyAttempt?.title}` || en.apply.jobApp.defaultTitle;

    return parse(apply.bannerTitle);
  };

  const renderQuestion = () => {
    const isLocation = currentStep.id === questionData.location.id;
    const Container = isLocation ? LocationQuestionContainer : QuestionContainer;
    return (
      <SmartQuizContainerOverride progress={quizProgress} ref={bannerRef} isCaseStudy={false}>
        <ProgressBar
          backgroundColor={colors.mediumGray}
          barHeight="12px"
          color={colors.purple20}
          useBarOnly
          useBarStripes
          percentage={quizProgress}
        />
        <BannerOverride>
          <BannerTitle>{renderTitle()}</BannerTitle>
          <ProgressText>
            {quizProgress}% {apply.complete}
          </ProgressText>
        </BannerOverride>

        <Container key={stepIndex}>{renderStep()}</Container>
        <Banner bottom centerButtons>
          {stepIndex > 0 && (
            <Button onClick={goPrevious} variant="secondary">
              {apply.prev}
            </Button>
          )}
          <SaveExitButton onClick={save} variant="underlined">
            {apply.exitButton}
          </SaveExitButton>
          {renderNextButton()}
        </Banner>
      </SmartQuizContainerOverride>
    );
  };

  const renderContent = () => {
    if (loading || !isDoneFetching)
      return <Spinner appplyBackground loading size="48px" spinnerColor={colors.primary} />;
    //if (!currentApplyAttempt || currentApplyAttempt) return renderUnstarted();
    const { isExit, renderStepDirectly } = currentStep;
    //if (isExit) return renderExit();
    if (renderStepDirectly) return renderStep(goNext);
    return renderQuestion();
  };

  const renderUnstarted = () => {
    if (loading || !isDoneFetching)
      return (
        <PageContainerOverride>
          {seo}
          <RadialBackgroundOverride>
            <AuthContainerOverrideApply isHeaderVisible={isLastScreen}>
              <Spinner appplyBackground loading size="48px" spinnerColor={colors.primary} />
            </AuthContainerOverrideApply>
          </RadialBackgroundOverride>
        </PageContainerOverride>
      );
    return (
      <UnstartedPageContainerOverride>
        {seo}
        <Unstarted startQuiz={startQuiz} loadingStartQuiz={loadingStartQuiz} />
      </UnstartedPageContainerOverride>
    );
  };

  if (!currentApplyAttempt) return renderUnstarted();

  if (currentStep?.isExit) {
    return renderExit();
  }

  return (
    <PageContainerOverride isHeaderVisible={isLastScreen}>
      {seo}

      <RadialBackgroundOverride>
        <AuthContainerOverrideApply isHeaderVisible={isLastScreen}>{renderContent()}</AuthContainerOverrideApply>
      </RadialBackgroundOverride>
    </PageContainerOverride>
  );
};

export default Apply;
