import { styled } from '@mui/material/styles';
import { colors } from '../../../../themes';
import { OpacityHoverAnimation, zoomOutAnimation } from '../../../sharedStyled';

const PopupContainer = styled('div')`
  filter: drop-shadow(0px 0px 40px rgb(209, 203, 246));
  background: ${colors.white};
  width: max(480px, 30vw);
  min-height: 50vh;
  border-radius: 16px;
  padding-top: 2.5rem;
  padding-left: 24px;
  padding-right: 24px;
  ${props => props.theme.breakpoints.down('sm')} {
    width: 86vw;
    margin: 30px auto;
    box-sizing: border-box;
    padding-left: 12px;
    padding-right: 12px;
  }
  color: ${colors.black};
  ${zoomOutAnimation()};
`;

const Title = styled('div')`
  font-size: 1.5rem;
  margin: 0;
  font-weight: 700;
  text-align: center;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
`;

const SubTitle = styled('div')`
  font-size: 1rem;
  font-weight: regular;
  text-align: center;
  margin-top: 16px;
`;

const ExpandableContainer = styled('div')`
  margin-top: 16px;
  .MuiAccordionSummary-content {
    margin-bottom: 0;
  }
  .MuiAccordionSummary-expandIconWrapper {
    margin-top: 12px;
  }
`;

const OptInHeader = styled('div')`
  display: flex;
  gap: 24px;
  font-weight: 700;
  font-size: 1rem;
  color: ${colors.black};
  align-items: center;

  svg {
    ${OpacityHoverAnimation()};
  }
`;

const Details = styled('div')`
  font-size: min(1rem, 14px);
  color: ${colors.black};
  margin-left: 48px;
`;

const ButtonContainer = styled('div')`
  margin-top: 24px;
  margin-bottom: 2.5rem;
  width: 100%;
  display: flex;
  justify-content: center;
  ${props => props.theme.breakpoints.down('sm')} {
    button {
      width: 100%;
    }
  }
`;

const ErrorMessage = styled(Details)`
  color: ${colors.red300};
`;

export { PopupContainer, Title, SubTitle, ExpandableContainer, OptInHeader, Details, ButtonContainer, ErrorMessage };
