const document = `
<h1>Smart’n User Agreement</h1>
<p><i>Updated: December 1, 2024</i></p>
<p>You must read, agree with, and accept all the terms and conditions in this Agreement and our Privacy Policy before using Smart’n’s Services.</p>
<p>We may update this Agreement by posting amended terms on our website at www.smartn.ca. Changes become effective 30 days after they are posted. Continued use of the Services after this period constitutes your acceptance of the updated terms.</p>
<p><strong>Prohibition on Use for Patient Diagnosis</strong></p>
<p><i>Information and Educational Purpose Only:</i></p>
<p>The content, tools, and information provided through Smart’n are for educational and informational purposes only. Smart’n is not a substitute for professional medical advice, diagnosis, or treatment.</p>
<p><i>No Patient Diagnosis:</i></p>
<p>You agree not to use Smart’n to diagnose, treat, or otherwise manage any patient’s condition or health. Smart’n does not provide medical advice or patient-specific recommendations.</p>
<p><i>User Responsibility:</i></p>
<p>Any reliance on the information provided through Smart’n for clinical decision-making is strictly prohibited. Users are responsible for seeking appropriate professional guidance in all patient-related matters.</p>
<p><i>Disclaimer:</i></p>
<p>Smart’n disclaims all liability for damages resulting from the misuse of its Services, including the improper use of its content for patient diagnosis or care.</p>
<p><i>Reporting Violations:</i></p>
<p>If Smart’n becomes aware of any misuse of its Services for patient diagnosis, it reserves the right to terminate access without notice and pursue appropriate legal remedies.</p>
<p><strong>License</strong></p>
<p>Smart’n offers a freemium model, providing users with both free and Paid subscription tiers. By downloading the App and accessing the Services, you are granted a limited, non-exclusive, non-transferable, and non-sublicensable license to use the Licensed Materials, subject to the following terms:</p>
<p><strong>Free Tier:</strong></p>
<p>Users in the free tier have access to limited features and content, provided for personal, non-commercial use only.</p>
<p><strong>Paid Tier:</strong></p>
<p>Paid subscribers gain access to additional features and content. Paid subscriptions are processed through the platform or in-app purchases via Apple or Google, depending on the platform used for the download.</p>
<p><strong>Mobile App Usage:</strong></p>
<p>You may download and use the Smart’n App on devices that you own or control, in accordance with the Apple App Store or Google Play Terms of Service.</p>
<p><i>Concurrent Use:</i></p>
<p>The license permits access to Smart’n content on multiple devices at different times. Concurrent login from multiple devices is prohibited.</p>
<p>Restrictions:</p>
<p>You may not reverse engineer, distribute, sublicense, or commercially exploit any content or features available through the App or Services.</p>
<p>Account Downgrades:</p>
<p>Paid users who do not renew their subscription will be automatically downgraded to the free tier. Access to Paid features will be restricted.</p>
<p><strong>In-App Purchases</strong></p>
<p><i>Payment Processing:</i></p>
<p>All in-app purchases are processed by Apple or Google and are subject to their respective payment policies. Smart’n does not access or store your payment information.</p>
<p>Refunds:</p>
<p>Refunds for in-app purchases must be requested through the platform from which the purchase was made (Apple or Google). Smart’n does not issue refunds for payments processed by third-party platforms.</p>
<p><i>Subscription Management:</i></p>
<p>Users can manage or cancel subscriptions directly through the application, their Apple or Google account settings.</p>
<p><strong>Training Data Use</strong></p>
<p><i>Purpose of Data Collection:</i></p>
<p>Smart’n may collect user interactions, queries, and feedback ("User Data") to improve the functionality and accuracy of its Platform and AI services, including the training of models.</p>
<p><i>Data Anonymization:</i></p>
<p>All User Data used for training purposes will be anonymized and aggregated to protect user privacy. Personal Identifiable Information (PII) will not be used in training.</p>
<p><i>Consent:</i></p>
<p>By using Smart’n’s Services, you consent to the collection and use of User Data for training purposes. If you do not agree, you may opt out by contacting support@smartn.ca</p>
<p><i>Opt-Out Option:</i></p>
<p>Users may opt out of contributing data for training by contacting Smart’n support or updating account preferences.</p>
<p><i>Ownership and Compliance:</i></p>
<P>You retain ownership of your personal data. Smart’n owns the AI models and improvements derived from anonymized and aggregated User Data.</p>
<p><strong>Limitation of Liability</strong></p>
<p>Smart’n provides its Services "as is" without warranties of any kind, express or implied. Smart’n, Apple, and Google are not liable for indirect, incidental, or consequential damages, including data loss, device damage, or service interruptions. Smart’n specifically disclaims liability for misuse of the Services for patient diagnosis or clinical decision-making.</p>
<p><strong>Third-Party Beneficiaries</strong></p>
<p><i>Apple:</i></p>
<p>You acknowledge and agree that Apple, and Apple’s subsidiaries, are third-party beneficiaries of this Agreement. Upon your acceptance of the terms, Apple has the right to enforce this Agreement as a third-party beneficiary.</p>
<p><i>Google:</i></p>
<p>You acknowledge and agree that Google, and Google’s subsidiaries, are third-party beneficiaries of this Agreement. Upon your acceptance of the terms, Google has the right to enforce this Agreement as a third-party beneficiary.</p>
<p><strong>Governing Law</strong></p>
<p>This Agreement is governed by the laws of Ontario, Canada. Disputes arising under this Agreement shall be resolved in the provincial or federal courts of Ontario.</p>
<p><strong>Contact Information</strong></p>
<p>For questions about this Agreement, contact us at:</p>
<p>Smart’n Legal<br>4211 Yonge Street, Suite 402<br>Toronto, ON<br>M2P 2A5<br>Email: support@smartn.ca</p>
`;

export default document;
