import { IStepComponent } from '../types';
import { Title } from './styled';
import { en } from '../../../i18n';
import useStep from './useStep';
import { EApplyTypes } from '../../types';

const { apply } = en;
const SubmitEnhancement = ({ question }: IStepComponent) => {
  const { renderSanitizedText, currentType } = useStep({ question });

  const text =
    currentType === EApplyTypes.JOB_APP ? en.apply.submitEnhancement.titleBJobApp : en.apply.submitEnhancement.titleB;
  return (
    <>
      <Title centerMobile>{apply.submitEnhancement.titleA}</Title>
      <Title centerMobile>{renderSanitizedText(text, true)}</Title>
    </>
  );
};

export default SubmitEnhancement;
