import { styled } from '@mui/material/styles';
import { colors } from '../../themes';
import { RadialBackground as AuthRadialBackground } from '../Auth/styled';
import { opacityInAnimation } from '../sharedStyled';

const MembershipContainer = styled('div')`
  background-color: ${colors.purple5};
  width: 100vw;
  display: grid;
  grid-template-columns: 1fr;
  min-height: calc(100vh - 64px);
  .smartn-apostrophe {
    color: ${colors.smartNApostrophe};
  }
`;

const RadialBackground = styled(AuthRadialBackground)`
  min-height: calc(100vh - 64px);
  padding: 82px 0;
  position: relative;
  height: auto;
  grid-row-start: 1;
  grid-column-start: 1;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
`;

const MembershipTitle = styled('div')`
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 33px;
  text-align: center;
  margin-bottom: 8px;
`;

const MembershipSubtitle = styled('div')`
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 28px;
  text-align: center;
  margin-bottom: 40px;
`;

const MembershipCardsContainer = styled('div')`
  display: flex;
  justify-content: center;
  gap: 26px;
  margin: 0 55px;
  ${props => props.theme.breakpoints.down('xmd')} {
    margin: 0 35px;
  }
  ${props => props.theme.breakpoints.down('md')} {
    flex-direction: column;
  }
  ${props => props.theme.breakpoints.down('lg')} {
    flex-wrap: wrap;
  }
`;

const ModalOverlay = styled('div')`
  width: 100vw;
  height: 100%;
  min-height: calc(100vh - 64px);
  grid-row-start: 1;
  grid-column-start: 1;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(24, 24, 24, 0.4);
  z-index: 10;
  ${props => props.theme.breakpoints.down('lg')} {
    align-items: flex-start;
  }
  ${opacityInAnimation(0.15)}
`;

const ModalContainer = styled('div')`
  background-color: ${colors.white};
  border-radius: 16px;
  padding: 40px 24px;
  width: 33%;
  max-width: 480px;
  display: flex;
  flex-direction: column;
  align-items: center;
  svg {
    margin-bottom: 32px;
  }
  ${props => props.theme.breakpoints.down('lg')} {
    width: 75%;
    margin-top: 30px;
  }
`;

const ModalTitle = styled('div')`
  font-size: 1.25rem;
  font-weight: 700;
  margin-bottom: 32px;
`;

const ModalContent = styled('div')`
  font-size: 1rem;
  text-align: center;
  margin-bottom: 32px;
  span {
    color: ${colors.purple30};
    font-weight: 700;
  }
  .bold {
    color: ${colors.black10};
  }
`;

const ModalButtonsContainer = styled('div')`
  display: flex;
  gap: 20px;
`;

export {
  MembershipContainer,
  MembershipTitle,
  MembershipSubtitle,
  MembershipCardsContainer,
  RadialBackground,
  ModalOverlay,
  ModalContainer,
  ModalTitle,
  ModalContent,
  ModalButtonsContainer,
};
