import { useRef, useState } from 'react';
import { useNavigations, useDispatch } from '../../hooks';
import { ESelectedSection, icons } from './types';
import { en } from '../../../i18n';
import { dashboardPath } from '../../Router/paths';
import { actions } from '../../redux/globalSlice';
import { SectionHeader, SectionHeaderText } from './styled';

const { navHeader } = en.dashboard;

interface IUseMainNavHeader {
  pathname: string;
  practiceRef: React.RefObject<HTMLDivElement>;
  assessRef: React.RefObject<HTMLDivElement>;
  reviewRef: React.RefObject<HTMLDivElement>;
  analyzeRef: React.RefObject<HTMLDivElement>;
  applyRef: React.RefObject<HTMLDivElement>;
  isMobile: boolean;
}

const enumSections = [
  ESelectedSection.PRACTICE,
  ESelectedSection.ASSESS,
  ESelectedSection.REVIEW,
  ESelectedSection.ANALYZE,
  ESelectedSection.APPLY,
];

const useSecondaryNavHeader = ({
  pathname,
  practiceRef,
  assessRef,
  reviewRef,
  analyzeRef,
  isMobile,
}: IUseMainNavHeader) => {
  const { navigateToHome, navigateToApply } = useNavigations();
  const [selectedTab, setSelectedTab] = useState<ESelectedSection>(ESelectedSection.PRACTICE);
  const { dispatch } = useDispatch();

  const toggleDrawer = () => {
    dispatch(actions.toggleSideBar({}));
  };

  const offsetTop = useRef(() => {
    const section = document.getElementById(`${ESelectedSection.PRACTICE}`);

    return section?.offsetTop || 0;
  });

  const sections = isMobile ? navHeader.secondaryMobile : navHeader.secondary;
  const sectionEnumsRefs = isMobile
    ? [practiceRef, assessRef, reviewRef, analyzeRef]
    : [practiceRef, assessRef, reviewRef, analyzeRef];

  const renderSections = () => {
    return sections.map((section, index) => {
      const Icon = icons[index];
      const sectionEnum = enumSections[index];

      const onClick = () => {
        if (pathname !== dashboardPath) navigateToHome(sectionEnum);
        if (sectionEnum === ESelectedSection.APPLY) {
          navigateToApply();
          return;
        }
        const section = document.getElementById(`${sectionEnum}`);
        const sectionTop = section?.offsetTop || 0;

        window.scrollTo({
          behavior: 'smooth',
          top: sectionTop - offsetTop.current(),
        });

        setSelectedTab(sectionEnum);
        if (isMobile) toggleDrawer();
      };

      return (
        <SectionHeader
          ref={sectionEnumsRefs[index]}
          onClick={onClick}
          isSelected={sectionEnum === selectedTab && pathname === dashboardPath}
          key={index}
        >
          <Icon />
          <SectionHeaderText>{section}</SectionHeaderText>
        </SectionHeader>
      );
    });
  };
  const getRef = () => {
    if (isMobile) return null;
    if (pathname === dashboardPath) return sectionEnumsRefs[selectedTab];
    return null;
  };
  return { offsetTop, getRef, renderSections, selectedTab, isHeaderAnimationDisabled: false };
};

export default useSecondaryNavHeader;
