import { useSelector } from 'react-redux';
import { useDispatch, useNavigations } from '../hooks';
import { actions, applySelector } from './redux/slice';
import { APPLY_EXTRA_QUESTION, HARD_CODED_QUIZ_ID } from './constants';
import useAttempts from '../hooks/useAttempts';
import { useEffect, useRef } from 'react';
import useSteps, { allRequiredSteps, IStep, questionData, allRequiredJobApps } from './useSteps';
import { EApplyStep, EApplyTypes, EAttemptTypes } from '../types';
import useApplyJobAttempts from './useApplyJobAttempts';
import useGetCurrentResponse from './useGetCurrentResponse';
interface IUseApply {
  fetchAttempts?: boolean;
}

const useApply = ({ fetchAttempts = false }: IUseApply) => {
  const { dispatch } = useDispatch();
  const { navigateToApplyProgress } = useNavigations();

  const {
    loading: loadingApply,
    loadingStartQuiz,
    questionAnswers,
    loadingAnswer,
    currentQuestionIndex: stepIndex,
    loadingFetchEnhance,
    currentType,
    loadingFetchJobApps,
    jobAppAttempts,
    jobAppsFetched,
    applyQuizFetched,
    applyEnhanceQuizFetched,
    currentStep: applyStep,
    jobAppId,
  } = useSelector(applySelector.allState);

  const loading =
    loadingApply ||
    loadingFetchEnhance ||
    loadingFetchJobApps ||
    (currentType === EApplyTypes.JOB_APP && loadingStartQuiz);

  const isDoneFetching = jobAppsFetched && applyQuizFetched && applyEnhanceQuizFetched;

  const { currentApplyAttempt: applyAttempt, currentApplyEnhancementAttempt, applyQuizState } = useAttempts();

  const { getCurrentResponse, getRequiredSteps } = useGetCurrentResponse();

  const getCurrentAttempt = () => {
    switch (currentType) {
      case EApplyTypes.ENHANCE:
        return currentApplyEnhancementAttempt;
      default:
        return applyAttempt;
    }
  };

  const currentApplyAttempt = getCurrentAttempt();

  useEffect(() => {
    if (!currentApplyAttempt) return;
    if (currentType !== EApplyTypes.JOB_APP && currentApplyAttempt.quizType === EAttemptTypes.APPLY_JOB_APPS) {
      dispatch(actions.setCurrentType({ type: EApplyTypes.JOB_APP }));
      return;
    }

    if (currentType !== EApplyTypes.BASE && currentApplyAttempt.quizType === EAttemptTypes.APPLY) {
      dispatch(actions.setCurrentType({ type: EApplyTypes.BASE }));
      return;
    }

    if (currentType !== EApplyTypes.ENHANCE && currentApplyAttempt.quizType === EAttemptTypes.APPLY_ENHANCE) {
      dispatch(actions.setCurrentType({ type: EApplyTypes.ENHANCE }));
      return;
    }
  }, [currentApplyAttempt]);

  const { renderStep, currentStep, getCurrentQuestion, getSteps } = useSteps({
    index: stepIndex,
    attempt: currentApplyAttempt,
  });

  useEffect(() => {
    if (currentStep?.isExit) {
      dispatch(actions.setCurrentQuestionIndex({ index: -1 }));
    }
  }, [currentStep]);

  const isProgressChecked = useRef(false);

  const save = () => {
    const { isDone } = getDoneStatus();
    if (isDone) {
      dispatch(actions.setCurrentQuestionIndex({ index: -1 }));
      return;
    }
    dispatch(actions.setCurrentStep({ step: EApplyStep.IN_PROGRESS }));
    navigateToApplyProgress();
  };

  useEffect(() => {
    if (loading || jobAppAttempts.length > 0 || !jobAppsFetched || currentType !== EApplyTypes.JOB_APP) return;
    // dispatch()
  }, [jobAppAttempts, jobAppsFetched, currentType]);

  const disableContinueStates = [EApplyStep.CONTINUE_PROGRESS, EApplyStep.QUIZ_STARTED];

  const getDoneStatus = () => {
    let lastDoneQuestionId = '';
    let isDone = true;
    if (!currentApplyAttempt)
      return {
        lastDoneQuestionId,
        isDone: false,
      };

    const isSubmitted = questionAnswers[APPLY_EXTRA_QUESTION]?.response;
    const steps = currentApplyAttempt.quizType === EAttemptTypes.APPLY_JOB_APPS ? allRequiredJobApps : allRequiredSteps;
    steps.forEach((step, index) => {
      const isCurrentQuestionDisabled = isQuestionDisabled(step);
      isDone = isDone && !isCurrentQuestionDisabled;
      if (
        !isCurrentQuestionDisabled &&
        ![questionData.certification.id, questionData.uploadResume.id].includes(step.id)
      ) {
        lastDoneQuestionId = step.id;
      }
    });

    if (isSubmitted) {
      isDone = true;
    }
    return {
      lastDoneQuestionId,
      isDone,
    };
  };
  useEffect(() => {
    if (!fetchAttempts) return;
    if (isProgressChecked.current) return;
    if (!currentApplyAttempt) return;
    isProgressChecked.current = true;
    if (disableContinueStates.includes(applyStep)) return;
    const isSubmitted = questionAnswers[APPLY_EXTRA_QUESTION]?.response;
    const { isDone } = getDoneStatus();

    if (isDone || isSubmitted) {
      dispatch(actions.setCurrentQuestionIndex({ index: -1 }));
      return;
    }
    //if(currentApplyAttempt===)
    dispatch(actions.setCurrentStep({ step: EApplyStep.IN_PROGRESS }));
    navigateToApplyProgress();
  }, [currentApplyAttempt]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, [stepIndex]);

  const startQuiz = () => {
    dispatch(actions.attemptQuiz({ quizId: HARD_CODED_QUIZ_ID }));
  };

  const isQuestionDisabled = (step: IStep, isLive?: boolean) => {
    const { id } = step;
    // const currentResponse = questionAnswers?.[id]?.response;
    const currentResponse = getCurrentResponse(id);
    if (step.checkDisabled) {
      const stepDisabled = step.checkDisabled(currentResponse, getCurrentQuestion(id));
      const liveDisabled = step.checkDisabledLive?.(currentResponse, getCurrentQuestion(id));
      if (!isLive) return stepDisabled;
      return stepDisabled || liveDisabled;
    }
    if (Array.isArray(currentResponse)) return currentResponse.length === 0;
    return currentResponse === undefined || currentResponse === '';
  };

  const isNextDisabled = () => isQuestionDisabled(currentStep, true);

  const goNext = () => {
    if (isNextDisabled()) return;
    //const currentResponse = questionAnswers?.[currentStep?.id]?.response;
    const currentResponse = getCurrentResponse(currentStep?.id);
    const attemptId =
      currentType === EApplyTypes.ENHANCE ? currentApplyEnhancementAttempt?.attemptId : currentApplyAttempt?.attemptId;

    const handleFileUploads = () => {
      const isFile = currentResponse instanceof File;
      if (isFile) {
        dispatch(
          actions.uploadRequest({
            questionId: questionData.uploadResume.id,
            file: currentResponse,
            attemptId: attemptId || '',
            type: currentType,
          }),
        );
        return;
      }

      if (currentResponse === null) {
        dispatch(
          actions.submitAnswer({
            attemptId: attemptId || '',
            questionId: currentStep?.id || '',
            response: currentResponse,
            noSucessSideEffects: !!currentStep.extraSubmitId,
            type: currentType,
          }),
        );
        return;
      }
      if (!currentStep.extraSubmitId) dispatch(actions.incrementQuestionIndex());
    };

    const handleSubmit = (overrideId?: string, noSideEffects?: boolean) => {
      dispatch(
        actions.submitAnswer({
          attemptId: attemptId || '',
          questionId: overrideId || currentStep?.id || '',
          response: true,
          noSucessSideEffects: noSideEffects,
          type: currentType,
        }),
      );
      dispatch(actions.setQuestionAnswer({ questionId: overrideId || currentStep?.id || '', response: true }));
      return;
    };

    if (currentStep?.id === questionData.uploadResume.id) {
      handleFileUploads();

      if (currentStep.extraSubmitId) handleSubmit(currentStep.extraSubmitId, currentResponse instanceof File);
      return;
    }
    if (currentStep?.isSubmit) {
      handleSubmit();
      return;
    }

    if (!currentResponse || currentStep?.skipSubmitAnswer) {
      dispatch(actions.incrementQuestionIndex());
      return;
    }

    dispatch(
      actions.submitAnswer({
        attemptId: attemptId || '',
        questionId: currentStep?.id || '',
        response: currentStep?.isSubmit ? true : currentResponse,
        type: currentType,
      }),
    );
  };

  const goPrevious = () => {
    dispatch(actions.decrementQuestionIndex());
  };

  const getTotalQuestions = () => {
    const stepsLength = getSteps();
    return stepsLength.length - 2;
  };

  const totalQuestions = getTotalQuestions() || 1;

  const quizProgress = Math.round((stepIndex / totalQuestions) * 100);
  // useEffect(() => {
  //   // if (!currentApplyAttempt && fetchAttempts) dispatch(actions.fetchApplyAttempts());
  //   //if (!currentApplyEnhancementAttempt && fetchAttempts) dispatch(actions.fetchApplyEnahcnementAttempts());
  //   // if (currentApplyAttempt && fetchAttempts && !currentApplyAttempt.questionsTransformed)
  //   //   dispatch(actions.transformAttemptRequest({ attempt: currentApplyAttempt }));
  // }, [currentApplyAttempt, currentApplyEnhancementAttempt]);

  const totalStepLength = getSteps().length;

  return {
    control: {
      startQuiz,
      goNext,
      goPrevious,
      renderStep,
      save,
    },
    state: {
      loading,
      loadingStartQuiz,
      currentApplyAttempt,
      stepIndex,
      quizProgress,
      totalQuestions,
      currentStep,
      isNextDisabled: isNextDisabled(),
      loadingAnswer,
      applyQuizState,
      currentType,
      totalStepLength,
      isLastScreen: stepIndex === -1,
      isDoneFetching,
      applyStep,
    },
  };
};

export default useApply;
