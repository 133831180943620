import { useSelector } from 'react-redux';
import { applySelector } from './redux/slice';
import { EApplyTypes } from '../types';
import { allRequiredJobApps, allRequiredSteps } from './useSteps';

const useGetCurrentResponse = () => {
  const { questionAnswers, currentType } = useSelector(applySelector.allState);

  const getCurrentResponse = (id?: string) => {
    if (!id) return '';
    const applyResponse = questionAnswers[id]?.response;

    return applyResponse;
  };

  const getCurrentQuestionAnswers = () => {
    return questionAnswers;
  };

  const getRequiredSteps = () => {
    if (currentType === EApplyTypes.JOB_APP) return allRequiredJobApps;
    return allRequiredSteps;
  };

  return { getCurrentResponse, getCurrentQuestionAnswers, getRequiredSteps };
};

export default useGetCurrentResponse;
