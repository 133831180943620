import { styled } from '@mui/material/styles';
import { colors } from '../../../themes';

const ContentContainer = styled('div')`
  padding-top: 60px;
  color: ${colors.black};
  width: 64%;
  ${props => props.theme.breakpoints.down('lg')} {
    width: 80%;
  }
  ${props => props.theme.breakpoints.down('md')} {
    width: 90%;
  }
  margin: auto;

  ${props => props.theme.breakpoints.down('sm')} {
    width: 91vw;
  }
`;

const Header = styled('h1')`
  font-weight: 700;
  margin: 0;
  margin-bottom: 16px;
  front-size: 1.25rem;
`;

const SubText = styled('div')`
  font-size: 1rem;
  margin-bottom: 32px;
`;

const ProgressContainer = styled('div')`
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;
`;

const ButtonProgressContainer = styled('div')`
  margin-bottom: 80px;
  width: fit-content;
  ${props => props.theme.breakpoints.down('sm')} {
    width: 100%;
    margin-bottom: 60px;
    button {
      width: 100%;
    }
  }
`;

const NurseGPTPromptContainer = styled('div')`
  background-color: ${colors.purple1};
  border: 1px solid rgba(226, 226, 231, 1);
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  min-height: 130px;
  box-sizing: border-box;
  display: flex;
  position: relative;
  justify-content: space-between;
  transition: all 0.2s ease-in-out;

  padding: 24px;
  ${props => props.theme.breakpoints.down('xsm')} {
    padding: 12px;
  }

  ${props => props.theme.breakpoints.down('md')} {
    flex-direction: column;
    gap: 16px;
    button {
      width: 100%;
    }
  }
`;

const PromptText = styled('div')`
  display: flex;
  flex-direction: column;
  width: 50%;
  ${props => props.theme.breakpoints.down('md')} {
    width: 80%;
  }
  gap: 8px;
  h3 {
    font-size: 0.875rem;
    color: ${colors.charcoal};
    margin-bottom: 4px;
    font-weight: 600;
    margin-top: 0;
  }

  h2 {
    font-size: 1rem;
    color: ${colors.black10};
    font-weight: 700;
    margin-top: 0;
    margin-bottom: 4px;
  }

  span {
    color: ${colors.grey500};
    font-size: 0.875rem;
    font-weight: 600;
  }
`;

const PromptButtonContainer = styled('div')`
  display: flex;
  align-items: center;
`;

const ProgressTitle = styled('div')`
  font-size: 0.875rem;
  font-weight: 600;
  margin-bottom: 4px;
`;
export {
  ContentContainer,
  Header,
  SubText,
  ProgressContainer,
  ButtonProgressContainer,
  PromptText,
  NurseGPTPromptContainer,
  PromptButtonContainer,
  ProgressTitle,
};
