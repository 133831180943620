import { EApplyTypes, IAttempt, ILooseObject, IQuestion, TApplyResponse } from '../types';
import EducationLevel from './steps/EducationLevel';
import Experience from './steps/Experience';
import Certifications from './steps/Certifications';
import EmploymentTypes from './steps/EmploymentTypes';
import HealthCareRating from './steps/HealthCareRating';
import ShiftWork from './steps/ShiftWork';
import Location from './steps/Location';
import Commute from './steps/Commute';
import Relocate from './steps/Relocate';
import License from './steps/License';
import UploadResume from './steps/UploadResume';
import SubmitScreen from './steps/SubmitScreen';
import ConfirmMissingCV from './steps/ConfirmMissingCV';
import WorkEnv from './steps/WorkEnv';
import Notifications from './steps/Notifications';
import { useSelector } from 'react-redux';
import { applySelector } from './redux/slice';
import Exit from './Exit';
import { supportedCountries, supportedStates } from '../classes/LocationInterface';

import { en } from '../../i18n';
import PatientCareRating from './steps/PatientCare';
import NursingRoles from './steps/NursingRoles';
import { Component } from 'react';
import PatientPopulation from './steps/PatientPopulation';
import Skills from './steps/Skills';
import JobImportance from './steps/JobImortance';
import SubmitEnhancement from './steps/SubmitEnhancement';
import {
  APPLY_CERTIFICATIONS_ID,
  APPLY_NOTIFICATIONS_ID,
  APPLY_EXTRA_QUESTION,
  APPLY_RESUME_UPLOAD_ID,
  APPLY_LOCATION_QUESTION_ID,
} from './constants';
import PreferredName from './steps/PreferredName';
import { firstNameQuestionId, roleQuestionId } from '../OnBoarding/constants';
import Role from './steps/Role';
import OptIn from './steps/OptIn';

const { apply } = en;

interface IUseSteps {
  index: number;
  attempt?: IAttempt;
}

type TCheckDisabled = TApplyResponse | TApplyResponse[] | ILooseObject;
interface IStep {
  id: string;
  Component: React.ElementType<any>;
  checkDisabled?: (currentResponse: TCheckDisabled, question?: IQuestion | null) => boolean;
  checkDisabledLive?: (currentResponse: TCheckDisabled, question?: IQuestion | null) => boolean;
  isExit?: boolean;
  buttonText?: string;
  skipSubmitAnswer?: boolean;
  isSubmit?: boolean;
  extraSubmitId?: string;
  renderStepDirectly?: boolean;
}

const checkDisabledMatrix = (response?: TCheckDisabled, currentQuestion?: IQuestion | null) => {
  const currentResponse = response as ILooseObject;
  if (!currentQuestion || !currentResponse) return true;
  const choices = currentQuestion.content.choices?.map(choice => choice.id);
  if (!choices) return true;

  return !choices.every(choiceId => currentResponse[choiceId] !== null && currentResponse[choiceId] !== undefined);
};

const questionData = {
  expierence: {
    id: '04b8214a-5497-11ef-b864-0242ac120002',
    Component: Experience,
    skipSubmitAnswer: false,
  },

  educationLevel: {
    id: '04b82794-5497-11ef-b864-0242ac120002',
    Component: EducationLevel,
  },

  certification: {
    id: APPLY_CERTIFICATIONS_ID,
    Component: Certifications,
    checkDisabled: (currentResponse?: any) => {
      return false;
      // if (!currentResponse) return true;
      // const valueExists = currentResponse.filter(item => item).length > 0;
      // return !valueExists;
    },
  },

  employmentTypes: {
    id: '04b82a1e-5497-11ef-b864-0242ac120002',
    Component: EmploymentTypes,
    checkDisabled: checkDisabledMatrix,
  },

  healthcareRating: {
    id: '04b82f1e-5497-11ef-b864-0242ac120002',
    Component: HealthCareRating,
    checkDisabled: checkDisabledMatrix,
  },

  shiftWork: {
    id: '04b83036-5497-11ef-b864-0242ac120002',
    Component: ShiftWork,
  },

  location: {
    id: APPLY_LOCATION_QUESTION_ID,
    Component: Location,
    checkDisabled: (response: TCheckDisabled) => {
      const currentResponse = response as ILooseObject;
      return !(currentResponse?.country && currentResponse?.state);
    },

    checkDisabledLive: (response: TCheckDisabled) => {
      const currentResponse = response as ILooseObject;
      return !(currentResponse?.country && currentResponse?.state && currentResponse?.city);
    },
  },

  commute: {
    id: '04b832ca-5497-11ef-b864-0242ac120002',
    Component: Commute,
  },

  relocate: {
    id: '04b836c6-5497-11ef-b864-0242ac120002',
    Component: Relocate,
    checkDisabled: checkDisabledMatrix,
  },

  license: {
    id: '04b837b6-5497-11ef-b864-0242ac120002',
    Component: License,
    checkDisabled: (response: TCheckDisabled) => {
      const currentResponse = response as ILooseObject;
      if (!currentResponse) return true;
      if (!currentResponse.locationA && !currentResponse.locationB) return true;
      return (
        !(currentResponse.locationA?.country && currentResponse.locationA?.state) &&
        !(currentResponse.locationB?.country && currentResponse.locationB?.state)
      );
    },
  },

  uploadResume: {
    id: APPLY_RESUME_UPLOAD_ID,
    Component: UploadResume,
    checkDisabled: (currentResponse: TCheckDisabled) => {
      return false;
    },
  },
  submit: {
    //id: APPLY_EXTRA_QUESTION,
    id: APPLY_RESUME_UPLOAD_ID,
    Component: SubmitScreen,
    checkDisabled: (currentResponse: TCheckDisabled) => {
      return false;
    },
    buttonText: apply.submitButton,
    extraSubmitId: APPLY_EXTRA_QUESTION,
  },
  confirmMissing: {
    id: APPLY_RESUME_UPLOAD_ID,
    Component: ConfirmMissingCV,
    checkDisabled: (currentResponse: TCheckDisabled) => {
      return false;
    },
    buttonText: apply.submitButton,
    extraSubmitId: APPLY_EXTRA_QUESTION,
  },
  notifications: {
    id: APPLY_NOTIFICATIONS_ID,
    Component: Notifications,
    checkDisabled: (response: TCheckDisabled) => {
      const currentResponse = response as ILooseObject;
      if (!currentResponse) return true;
      switch (true) {
        case currentResponse?.email?.checked && !currentResponse.text?.checked:
          return false;

        case currentResponse?.text?.checked:
          if (currentResponse?.text?.number) return false;
      }

      return true;
    },
    buttonText: apply.submitButton,
  },
  noService: {
    id: '030dabb2-5497-11ef-b864-0242ac120002',
    Component: (props: any) => <Exit supported={false} {...props} />,
    isExit: true,
  },

  suportedService: {
    id: '030dabb2-5497-11ef-b864-0242ac120002',
    Component: (props: any) => <Exit supported {...props} />,
    isExit: true,
  },

  preferredName: {
    id: firstNameQuestionId,
    Component: PreferredName,
  },

  role: {
    id: roleQuestionId,
    Component: Role,
  },
  optIn: {
    id: '5b0091d0-b4ab-11ef-9cd2-0242ac120002',
    Component: OptIn,
    renderStepDirectly: true,
  },
  exitJobApp: {
    id: '5b0091d0-b4ab-11ef-9cd2-0242ac120002',
    Component: (props: any) => <Exit supported {...props} />,
    isExit: true,
  },
};

//  { questionId: '52f481f8-7366-11ef-b864-0242ac120002' },
//   { questionId: '52f48518-7366-11ef-b864-0242ac120002' },
//   { questionId: '52f4866c-7366-11ef-b864-0242ac120002' },
//   { questionId: '52f48798-7366-11ef-b864-0242ac120002' },
//   { questionId: '52f488c4-7366-11ef-b864-0242ac120002' },
//   { questionId: '52f48c3e-7366-11ef-b864-0242ac120002' },

const enahnceQuestionData = {
  workEnv: {
    id: '52f481f8-7366-11ef-b864-0242ac120002',
    Component: WorkEnv,
  },

  patientCare: {
    id: '52f48518-7366-11ef-b864-0242ac120002',
    Component: PatientCareRating,
    checkDisabled: checkDisabledMatrix,
  },

  nursingRoles: {
    id: '52f4866c-7366-11ef-b864-0242ac120002',
    Component: NursingRoles,
  },

  patientPopulation: {
    id: '52f48798-7366-11ef-b864-0242ac120002',
    Component: PatientPopulation,
  },
  skills: {
    id: '52f488c4-7366-11ef-b864-0242ac120002',
    Component: Skills,
  },
  jobImportance: {
    id: '52f48c3e-7366-11ef-b864-0242ac120002',
    Component: JobImportance,
    checkDisabled: checkDisabledMatrix,
  },

  submit: {
    id: 'b9265e7c-77f0-11ef-b864-0242ac120002',
    Component: SubmitEnhancement,
    checkDisabled: (currentResponse: TCheckDisabled) => {
      return false;
    },
    //skipSubmitAnswer: true,
    buttonText: apply.submitButton,
    isSubmit: true,
  },

  noService: {
    id: '52f48c3e-7366-11ef-b864-0242ac120002',
    Component: (props: any) => <Exit supported={false} {...props} />,
    isExit: true,
  },

  suportedService: {
    id: '52f48c3e-7366-11ef-b864-0242ac120002',
    Component: (props: any) => <Exit supported {...props} />,
    isExit: true,
  },
};

const baseSteps: IStep[] = [
  questionData.expierence,
  questionData.educationLevel,
  questionData.certification,
  questionData.employmentTypes,
  questionData.healthcareRating,
  questionData.shiftWork,
  questionData.location,
  questionData.commute,
  questionData.relocate,
  questionData.license,
  questionData.uploadResume,
  //questionData.noService,
  //questionData.suportedService,
];

const enhanceSteps: IStep[] = [
  enahnceQuestionData.workEnv,
  enahnceQuestionData.patientCare,
  enahnceQuestionData.nursingRoles,
  enahnceQuestionData.patientPopulation,
  enahnceQuestionData.skills,
  enahnceQuestionData.jobImportance,
  enahnceQuestionData.submit,
];

const jobAppSteps: IStep[] = [
  questionData.preferredName,
  questionData.role,
  questionData.expierence,
  questionData.educationLevel,
  questionData.certification,
  questionData.employmentTypes,
  questionData.healthcareRating,
  questionData.shiftWork,
  questionData.location,
  questionData.commute,
  questionData.relocate,
  questionData.license,
  questionData.uploadResume,
  // optin
];

const allRequiredSteps: IStep[] = [...baseSteps, questionData.notifications];

const allRequiredJobApps: IStep[] = [...jobAppSteps, questionData.optIn];

const useSteps = ({ index, attempt }: IUseSteps) => {
  const { questionAnswers, currentType } = useSelector(applySelector.allState);

  const isSupported = () => {
    const location = questionAnswers[questionData.location.id];
    const willingToRelocate = questionAnswers[questionData.relocate.id] as ILooseObject;
    if (!location?.response) return false;
    const { country, state } = location.response as ILooseObject;
    const allowedStates = supportedStates[country];
    if (!allowedStates) return false;
    if (!allowedStates.includes(state) && willingToRelocate?.response?.anotherProvince !== 'yes') return false;
    return true;
  };

  // const getSteps = () => {
  //   if (currentType === EApplyTypes.ENHANCE) {
  //     return isSupported()
  //       ? [...enhanceSteps, enahnceQuestionData.suportedService as IStep]
  //       : [...enhanceSteps, enahnceQuestionData.noService as IStep];
  //   }
  //   let stepsFlow: IStep[] = baseSteps;
  //   const isCVUploaded = questionAnswers[questionData.uploadResume.id];
  //   if (isCVUploaded?.response && (isCVUploaded?.response as ILooseObject).name)
  //     stepsFlow = [...stepsFlow, questionData.submit as IStep];
  //   else stepsFlow = [...stepsFlow, questionData.confirmMissing as IStep];

  //   const location = questionAnswers[questionData.location.id];
  //   const willingToRelocate = questionAnswers[questionData.relocate.id] as ILooseObject;
  //   if (!location?.response) {
  //     stepsFlow = [...stepsFlow, questionData.noService];
  //     return stepsFlow;
  //   }

  const getSteps = () => {
    if (currentType === EApplyTypes.ENHANCE) return [...enhanceSteps, enahnceQuestionData.suportedService as IStep];
    let stepsFlow: IStep[] = currentType === EApplyTypes.JOB_APP ? jobAppSteps : baseSteps;
    const isCVUploaded = questionAnswers[questionData.uploadResume.id];
    if (isCVUploaded?.response && (isCVUploaded?.response as ILooseObject).name)
      stepsFlow = [...stepsFlow, questionData.submit as IStep];
    else stepsFlow = [...stepsFlow, questionData.confirmMissing as IStep];
    // Put opt in here notif
    if (currentType === EApplyTypes.JOB_APP)
      stepsFlow = [...stepsFlow, questionData.optIn as IStep, questionData.exitJobApp];
    else stepsFlow = [...stepsFlow, questionData.notifications as IStep, questionData.suportedService];
    return stepsFlow;
  };

  const getStep = () => {
    const steps = getSteps();
    if (index === -1) return steps[steps.length - 1];

    return steps[index];
  };

  const step = getStep();

  const renderStep = (goNext?: () => void) => {
    const question = getCurrentQuestion();
    const { Component, isSubmit } = step;

    if (!question && !isSubmit) return null;

    return <Component goNext={goNext} question={question} />;
  };

  const getCurrentQuestion = (overrideId?: string) => {
    if (!attempt || !attempt.questionsTransformed) return null;
    const { id: questionId } = step;
    const question = attempt.questionsTransformed[overrideId || questionId];
    return question;
  };
  return {
    renderStep,
    currentStep: step,
    getCurrentQuestion,
    getSteps,
  };
};

export type { IStep };
export { questionData, allRequiredSteps, allRequiredJobApps };
export default useSteps;
