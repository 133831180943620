import { useSelector } from 'react-redux';
import { useDispatch, useNavigations, useSeoTags } from '../../hooks';
import { actions, applySelector } from '../redux/slice';
import { useEffect, useMemo } from 'react';
import { EApplyNewQuiz, EApplyStep, EApplyTypes } from '../../types';
import { en } from '../../../i18n';
import {
  ContentContainer,
  Header,
  SubText,
  ProgressContainer,
  PromptText,
  NurseGPTPromptContainer,
  PromptButtonContainer,
  ProgressTitle,
  ButtonProgressContainer,
} from './styled';
import Button from '../../components/Button';
import ProgressBar from '../../components/ProgressBar';
import { colors } from '../../../themes';
import { UnstartedPageContainerOverride } from '../styled';
import useAttempts from '../../hooks/useAttempts';
import { allRequiredSteps, IStep, questionData } from '../useSteps';
import { APPLY_EXTRA_QUESTION, APPLY_JOB_ID_QUESTION, JOB_APP_QUIZ_ID } from '../constants';
import useGetCurrentResponse from '../useGetCurrentResponse';
import { filter } from 'lodash';

const { apply } = en;

interface IProgress {}

const Progress = () => {
  const seo = useSeoTags({ pageName: 'apply' });
  const { currentStep, currentType } = useSelector(applySelector.allState);
  const { navigateToApply, navigateToNurseGPTApplyPrompts } = useNavigations();
  const { dispatch } = useDispatch();

  const { getCurrentQuestionAnswers, getRequiredSteps } = useGetCurrentResponse();

  const questionAnswers = getCurrentQuestionAnswers();
  const { currentApplyAttempt: applyAttempt, currentApplyEnhancementAttempt, currentJobAppAttempt } = useAttempts();

  const getCurrentAttempt = () => {
    switch (currentType) {
      case EApplyTypes.ENHANCE:
        return currentApplyEnhancementAttempt;

      default:
        return applyAttempt;
    }
  };

  const currentApplyAttempt = getCurrentAttempt();

  useEffect(() => {
    if (currentStep !== EApplyStep.IN_PROGRESS) {
      console.log(currentStep, 'OKKKK HERE??????????????????????/');
      navigateToApply();
    }
    if (currentApplyEnhancementAttempt) dispatch(actions.setNewQuizType({ quizType: EApplyNewQuiz.ENHANCEMENT }));
    else dispatch(actions.setNewQuizType({ quizType: EApplyNewQuiz.BASE }));
  }, [currentStep]);

  const progress = useMemo(() => {
    if (!currentApplyAttempt) return 0;
    const filteredQuestions = [APPLY_JOB_ID_QUESTION];
    const questionIds = currentApplyAttempt?.questions.filter(
      question => !filteredQuestions.includes(question.questionId),
    );

    const total = questionIds?.length;
    const totalAnswered = questionIds.reduce((accum, qId) => {
      if (!!questionAnswers[qId.questionId]) return accum + 1;
      return accum;
    }, 0);
    return totalAnswered / total;
  }, [currentApplyAttempt]);

  const percentage = Math.round(progress * 100);

  const continueQuiz = () => {
    const allSteps = getRequiredSteps();

    let lastDoneQuestionId = '';
    const filteredQuestions = [APPLY_JOB_ID_QUESTION, questionData.certification.id, questionData.uploadResume.id];
    allSteps.forEach(step => {
      if (filteredQuestions.includes(step.id)) return;
      if (!!questionAnswers[step.id]) lastDoneQuestionId = step.id;
    });
    const findAllIndicesById = (arr: IStep[], id: string): number[] =>
      arr.reduce((indices: number[], item: IStep, index: number) => {
        if (item.id === id) indices.push(index);

        return indices;
      }, []);
    if (lastDoneQuestionId === questionData.license.id) lastDoneQuestionId = questionData.uploadResume.id;
    if (lastDoneQuestionId) {
      const steps = getRequiredSteps();
      const indices = findAllIndicesById(steps, lastDoneQuestionId).sort((a, b) => b - a);
      if (indices.length > 0) {
        const targetIndex = indices[0] + 1;

        if (targetIndex < steps.length) {
          dispatch(actions.setCurrentQuestionIndex({ index: targetIndex }));
        } else {
          dispatch(actions.setCurrentQuestionIndex({ index: 0 }));
        }
      }
    }
    dispatch(actions.setCurrentStep({ step: EApplyStep.CONTINUE_PROGRESS }));
    navigateToApply();
  };

  return (
    <UnstartedPageContainerOverride>
      {seo}
      <ContentContainer>
        <Header>{apply.applyProgress.title}</Header>
        <SubText>{apply.applyProgress.subTitle}</SubText>
        <ButtonProgressContainer>
          <ProgressContainer>
            <ProgressTitle>
              {percentage}
              {apply.applyProgress.progress}
            </ProgressTitle>
            <ProgressBar
              useBarOnly
              barHeight="8px"
              color={colors.highlightGreen}
              percentage={percentage}
              upperBound={100}
              lowerBound={0}
              useBarStripes={false}
              useMountAnimation
            />
          </ProgressContainer>

          <Button onClick={continueQuiz} variant="primary">
            {apply.applyProgress.button}
          </Button>
        </ButtonProgressContainer>

        <NurseGPTPromptContainer>
          <PromptText>
            <h2>{apply.applyProgress.nurseGPTPrompt.title}</h2>
            <span>{apply.applyProgress.nurseGPTPrompt.text}</span>
          </PromptText>
          <PromptButtonContainer>
            <Button variant="secondary" onClick={navigateToNurseGPTApplyPrompts}>
              {apply.applyProgress.nurseGPTPrompt.button}
            </Button>
          </PromptButtonContainer>
        </NurseGPTPromptContainer>
      </ContentContainer>
    </UnstartedPageContainerOverride>
  );
};

export default Progress;
