import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import useDispatch from './useDispatch';
import { actions as studySpaceActions, quizSelectors } from '../StudySpace/redux/slice';
import { actions as applyActions, applySelector } from '../Apply/redux/slice';
import { actions as clickActions, clicksSelector } from '../ClicksQuiz/redux/slice';
import useJobApplicationInit from '../Apply/useJobApplicationInit';
import useApplyJobAttempts from '../Apply/useApplyJobAttempts';

const useInitializeAttempts = (isLoggedIn: boolean) => {
  const { onBoardingAttempts, loadingOnBoardingAttempts, placementAttempts, loadingPlacementAttempts } = useSelector(
    quizSelectors.allState,
  );

  const {
    attempts: applyAttempts,
    enhancementsAttempts,
    loading: loadingApply,
    loadingFetchEnhance,
    jobAppAttempts,
    loadingFetchJobApps,
    jobAppsFetched,
    applyQuizFetched,
  } = useSelector(applySelector.allState);

  const { attempts: clicksAttempts, loading: loadingClicks } = useSelector(clicksSelector.allState);

  const { dispatch } = useDispatch();
  const { storedJobAppId } = useJobApplicationInit();
  useApplyJobAttempts({ startAttempt: true });

  useEffect(() => {
    if (!isLoggedIn) return;
    if (!onBoardingAttempts && !loadingOnBoardingAttempts) {
      dispatch(studySpaceActions.fetchOnBoardingAttempts());
    }
    if (!placementAttempts && !loadingPlacementAttempts) {
      dispatch(studySpaceActions.fetchPlacementAttempts());
    }

    if ((!applyAttempts || applyAttempts?.length === 0) && !applyQuizFetched) {
      dispatch(applyActions.fetchApplyAttempts());
    }

    if ((!enhancementsAttempts || enhancementsAttempts?.length === 0) && !loadingFetchEnhance) {
      dispatch(applyActions.fetchApplyEnahcnementAttempts());
    }

    if ((!clicksAttempts || clicksAttempts?.length === 0) && !loadingClicks) {
      dispatch(clickActions.fetchClicksAttempts());
    }

    if (jobAppAttempts.length === 0 && !jobAppsFetched && !loadingFetchJobApps) {
      dispatch(applyActions.fetchApplyJobAppsAttempts({ jobId: storedJobAppId }));
    }
  }, [isLoggedIn]);
};

export default useInitializeAttempts;
