const HARD_CODED_QUIZ_ID = '57c57982-5497-11ef-b864-0242ac120002';
const ENHANCE_HARD_CODED_QUIZ_ID = '14753ea4-7366-11ef-b864-0242ac120002';
const JOB_APP_QUIZ_ID = '7f71bf9a-b306-11ef-9cd2-0242ac120002';

const APPLY_CERTIFICATIONS_ID = '04b828ca-5497-11ef-b864-0242ac120002';
const APPLY_NOTIFICATIONS_ID = '030dabb2-5497-11ef-b864-0242ac120002';
const APPLY_EXTRA_QUESTION = 'd85a2498-78e2-11ef-b864-0242ac120002';
const APPLY_JOB_ID_QUESTION = 'bd6ee168-b49d-11ef-9cd2-0242ac120002';
const APPLY_RESUME_UPLOAD_ID = '04b838a6-5497-11ef-b864-0242ac120002';
const APPLY_LOCATION_QUESTION_ID = '04b831da-5497-11ef-b864-0242ac120002';

const IGNORED_QUESTIONS_DONE = [APPLY_CERTIFICATIONS_ID, APPLY_NOTIFICATIONS_ID];
const MODAL_CLASS_NAME = 'apply-modal';
const JOB_APP_QUERY_PARAM = 'job_id';

const NOTIFICATIONS_ID = 'notifications';
const SIMILAR_ROLES_ID = 'similarRoles';
const PROFILE_MATCHES_ID = 'profileBasedMatches';

const jobIdLabelMap = {
  1: 'Nurse Community Evangelist and Content Specialist',
  2: 'Nurse Advisory Council Member',
  3: 'Lead, Nurse Community and Content',
};

export {
  HARD_CODED_QUIZ_ID,
  ENHANCE_HARD_CODED_QUIZ_ID,
  IGNORED_QUESTIONS_DONE,
  APPLY_CERTIFICATIONS_ID,
  APPLY_EXTRA_QUESTION,
  APPLY_NOTIFICATIONS_ID,
  MODAL_CLASS_NAME,
  JOB_APP_QUERY_PARAM,
  jobIdLabelMap,
  JOB_APP_QUIZ_ID,
  APPLY_JOB_ID_QUESTION,
  NOTIFICATIONS_ID,
  SIMILAR_ROLES_ID,
  PROFILE_MATCHES_ID,
  APPLY_RESUME_UPLOAD_ID,
  APPLY_LOCATION_QUESTION_ID,
};
