import { styled } from '@mui/material/styles';
import { colors } from '../../../themes';
import { LandingContainer } from '../Landing/styled';
import { shouldForwardProps } from '../../utils';

const Container = styled('div')`
  color: ${colors.black10};
  .message-container {
    margin-bottom: ${props => props.theme.spacing(3)};
    ${props => props.theme.breakpoints.down('md')} {
      width: min(90vw, 1240px);
      box-sizing: border-box;
      padding: 12px;
    }
  }
`;

enum EStatusTag {
  ACTIVE,
  PAYMENT_ISSUE,
  CANCELLED,
  DEPRECATED,
}

interface IStatusTag {
  status: EStatusTag;
}

const LandingContainerSubscribed = styled(LandingContainer)`
  flex-direction: column;
  h1 {
    margin: 0;
    font-weight: 700;
    font-size: 1.25rem;
  }

  ${props => props.theme.breakpoints.down('sm')} {
    font-size: 1rem;
  }
`;

const TitleContainer = styled('div')`
  h1 {
    font-size: 1.5rem;
    font-weight: 700;
    margin: 0;
    margin-bottom: ${props => props.theme.spacing(1.5)};
  }
  h2 {
    margin: 0;
    font-size: 1rem;
    front-weight: 600;
  }
  margin-bottom: ${props => props.theme.spacing(5)};
  margin-left: auto;
  margin-right: auto;
  width: min(80vw, 1240px);
  ${props => props.theme.breakpoints.down('md')} {
    width: min(90vw, 1240px);
  }
  ${props => props.theme.breakpoints.down('sm')} {
    h1 {
      font-size: 1.2rem;
    }
    h2 {
      font-size: 0.9rem;
    }
  }
`;

const PopupHeaderContainer = styled('div')`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 50px;
  padding-right: 50px;
  border-bottom: 1px solid ${colors.lightGrey};
  span {
    font-weight: 700;
  }

  ${props => props.theme.breakpoints.down('sm')} {
    padding-left: 25px;
    padding-right: 25px;
    padding-top: 20px;
    padding-bottom: 20px;
    flex-direction: column;
    gap: 15px;
  }
`;

const PopupContentContainer = styled('div')`
  margin-top: ${props => props.theme.spacing(5)};
  padding-left: 50px;
  padding-right: 50px;
  box-sizing: border-box;
  ${props => props.theme.breakpoints.down('sm')} {
    padding-left: 25px;
    padding-right: 25px;
  }
`;

const ContentHeader = styled('div')`
  display: flex;
  justify-content: space-between;
  font-size: 1.125rem;
  font-weight: 700;
  margin-bottom: ${props => props.theme.spacing(1)};
`;

const StatusTag = styled('div', {
  shouldForwardProp: prop => shouldForwardProps(prop, ['status']),
})<IStatusTag>(({ status, theme }) => {
  const getColors = () => {
    switch (status) {
      case EStatusTag.ACTIVE:
        return { text: colors.green20, background: colors.green15 };
      case EStatusTag.DEPRECATED:
        return { text: colors.purple600, background: 'rgba(96, 75, 223, 0.2)' };
      case EStatusTag.PAYMENT_ISSUE:
      case EStatusTag.CANCELLED:
        return { text: colors.red300, background: colors.pink };
    }
  };

  return `
 padding: 4px 15px 4px 15px;
 border-radius:4px;
 background:${getColors()?.background};
 color:${getColors()?.text};
 font-size:0.875rem;
 font-weight:700;
`;
});

const MembershipSubTitle = styled('span')`
  font-size: 1rem;
  color: ${colors.black};
  font-weight: 600;
  margin-bottom: ${props => props.theme.spacing(3)};
  display: block;
  max-width: 80%;
`;

const PlanPrice = styled('div')`
  font-size: 1.125rem;
  color: ${colors.black};
  font-weight: 700;
  margin-bottom: ${props => props.theme.spacing(4)};
`;

const AddonsSection = styled('div')`
  margin-top: ${props => props.theme.spacing(8)};
  margin-bottom: ${props => props.theme.spacing(5)};
`;

const AddonsSubTitle = styled('span')`
  font-size: 1.125rem;
  color: ${colors.black};
  font-weight: 600;
  margin-bottom: ${props => props.theme.spacing(2)};
  display: block;
`;

const AddonCard = styled('div')`
  padding-top: 50px;
  padding-bottom: 50px;
  padding-left: 20px;
  padding-right: 20px;
  background: ${colors.lightestGrey};
  border-radius: 8px;
  border: 1px solid ${colors.purple10};
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.spacing(3)};
  position: relative;
  margin-top: ${props => props.theme.spacing(5)};
  align-items: self-start;
  button {
    min-width: 160px;
  }
  ${props => props.theme.breakpoints.down('sm')} {
    button {
      width: 100%;
      padding: 6.5px 16px;
    }
  }
`;

const TitleAddonCard = styled('div')`
  h1 {
    font-size: 1rem;
    front-weight: 700;
    margin: 0;
    margin-bottom: ${props => props.theme.spacing(0.7)};
  }

  h2 {
    margin: 0;
    font-size: 0.875rem;
    font-weight: 600;
    color: ${colors.black};
  }

  ${props => props.theme.breakpoints.down('sm')} {
    h2 {
      width: 100%;
    }
  }
`;

const PricingAddonCard = styled('div')`
  display: flex;
  justify-content: space-between;
  font-size: 1.125rem;
  align-items: center;
  font-weight: 600;
  button {
    min-width: 160px;
  }
  ${props => props.theme.breakpoints.down('sm')} {
    justify-content: center;
    width: 100%;
  }
`;

const AddonTag = styled('div')`
  position: absolute;
  top: -11.5px;
  padding: 2px 15px 2px 15px;
  background-color: ${colors.purple5};
  color: ${colors.primary};
  font-weight: 700;
  font-size: 0.875rem;
  border-radius: 4px;
  width: 112px;
  display: flex;
  gap: ${props => props.theme.spacing(1)};
  justify-content: center;
  box-sizing: border-box;
  align-items: center;
`;

const PaymentIssueMessageContent = styled('div')`
  display: flex;
  justify-content: space-between;
  width: 100%;
  font-weight: 600;
`;

const PaymentIssueText = styled('div')`
  display: flex;
  flex-direction: column;
`;

const AddonActivatedTag = styled('div')`
  min-width: 160px;
  padding: 8px 16px 8px 16px;
  background: ${colors.purple10};
  color: ${colors.purple40};
  text-align: center;
  font-weight: 700;
  font-size: 0.875rem;
  border-radius: 4px;
  box-sizing: border-box;

  ${props => props.theme.breakpoints.down('sm')} {
    width: 100%;
  }
`;

const CancelActionsContainer = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const RenewalInfo = styled('span')`
  color: ${colors.grey500};
`;

const ErrorMessageContent = styled(PaymentIssueMessageContent)`
  align-items: center;
`;

export {
  Container,
  TitleContainer,
  PopupHeaderContainer,
  PopupContentContainer,
  LandingContainerSubscribed,
  ContentHeader,
  EStatusTag,
  StatusTag,
  MembershipSubTitle,
  PlanPrice,
  AddonsSection,
  AddonsSubTitle,
  AddonCard,
  TitleAddonCard,
  PricingAddonCard,
  AddonTag,
  PaymentIssueMessageContent,
  PaymentIssueText,
  AddonActivatedTag,
  CancelActionsContainer,
  RenewalInfo,
  ErrorMessageContent,
};
