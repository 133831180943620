import {
  InvitesHeader,
  InvitesHeaderDisabled,
  InvitesSubHeader,
  JobsContainer,
  JobsContentContainer,
  StyledButton,
  TabContentJobs,
  TabTitle,
} from './styled';
import { en } from '../../../i18n';
import { TopicsFilterContainer, TopicsFilter } from '../../Home/Analyze/styled';
import { useState } from 'react';
import { useNavigations } from '../../hooks';
import { useSelector } from 'react-redux';
import { applySelector } from '../redux/slice';
import useStartApplyEnhance from '../useStartApplyEnhance';
import { EAttemptTypes, EQuizTypes } from '../../types';
import { questionData } from '../useSteps';

import ErrorMessage from './ErrorMessage';
import StatusTable from './StatusTable';

const { apply } = en;
const {
  exit: { jobs },
} = apply;

enum ETabTitle {
  INVITES = 'Job Invites',
  APPLICATIONS = 'My applications',
}

const Jobs = () => {
  const [selectedTab, setSelectedTab] = useState<ETabTitle>(ETabTitle.INVITES);
  const { navigateToNurseGPTApplyPrompts } = useNavigations();
  const { questionAnswers, loadingAnswer } = useSelector(applySelector.allState);
  const { currentApplyAttempt, currentJobAppAttempt } = useStartApplyEnhance();

  const isMatchingDisabled = () => {
    if (currentApplyAttempt?.quizType === EAttemptTypes.APPLY_JOB_APPS) {
      const response = questionAnswers[questionData.optIn.id];
      if (loadingAnswer || !response) return true;
      if (!Array.isArray(response.response)) return true;
      if (response.response.length < 3) return true;
    }

    return false;
  };

  const onSelectTab = (tab: ETabTitle) => () => {
    setSelectedTab(tab);
  };

  const renderInvitesContent = () => {
    const matchingDisabled = isMatchingDisabled();
    if (matchingDisabled) {
      return (
        <>
          <InvitesHeaderDisabled>{jobs.matchingDisabledHeader}</InvitesHeaderDisabled>
          <ErrorMessage />
        </>
      );
    }

    return (
      <>
        <InvitesHeader>{jobs.invitesHeader}</InvitesHeader>
        <InvitesSubHeader>{jobs.invitesSubHeader}</InvitesSubHeader>
        <StyledButton onClick={navigateToNurseGPTApplyPrompts} variant="primary">
          {jobs.button}
        </StyledButton>
      </>
    );
  };

  const renderApplicationsContent = () => {
    if (!!currentJobAppAttempt) return <StatusTable attempt={currentJobAppAttempt} />;

    return (
      <>
        <InvitesHeader>{jobs.noJobApplicationsTitle}</InvitesHeader>
        <InvitesSubHeader>{jobs.noJobApplicationsSubText}</InvitesSubHeader>
      </>
    );
    return null;
  };

  const renderContent = () => {
    if (selectedTab === ETabTitle.INVITES) return renderInvitesContent();
    return renderApplicationsContent();
  };
  return (
    <JobsContainer>
      <TabTitle>{jobs.title}</TabTitle>

      <TabContentJobs>
        <TopicsFilterContainer>
          <TopicsFilter onClick={onSelectTab(ETabTitle.INVITES)} isSelected={selectedTab == ETabTitle.INVITES}>
            {ETabTitle.INVITES}
          </TopicsFilter>
          <TopicsFilter
            onClick={onSelectTab(ETabTitle.APPLICATIONS)}
            isSelected={selectedTab == ETabTitle.APPLICATIONS}
          >
            {ETabTitle.APPLICATIONS}
          </TopicsFilter>
        </TopicsFilterContainer>
        <JobsContentContainer>{renderContent()}</JobsContentContainer>
      </TabContentJobs>
    </JobsContainer>
  );
};

export default Jobs;
