import { IAttempt } from '../../types';

import {
  TableTitle,
  ApplicationCardContainer,
  ApplicationsContainer,
  TableHeaderContainer,
  TableContainer,
  TabTitle,
  StatusTag,
  AttemptTitle,
  RightTableTitle,
} from './styled';

import { en } from '../../../i18n';
const { apply } = en;
const {
  exit: { jobs },
} = apply;

interface IStatusTable {
  attempt: IAttempt;
}

const StatusTable = ({ attempt }: IStatusTable) => {
  return (
    <TableContainer>
      <TableHeaderContainer>
        <TableTitle>{jobs.tableTitle}</TableTitle>
        <RightTableTitle>{jobs.statusTitle}</RightTableTitle>
      </TableHeaderContainer>

      <ApplicationsContainer>
        <ApplicationCardContainer>
          <AttemptTitle>{attempt.title}</AttemptTitle>
          <StatusTag>{jobs.statusMap.submitted}</StatusTag>
        </ApplicationCardContainer>
      </ApplicationsContainer>
    </TableContainer>
  );
};

export default StatusTable;
