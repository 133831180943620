import { styled } from '@mui/material/styles';
import { colors } from '../../../themes';
import { shouldForwardProps } from '../../utils';

interface ISectionHeader {
  isSelected?: boolean;
  disabled?: boolean;
}

const NavHeaderContainer = styled('div')`
  border-radius: 4px;
  color: ${colors.black};
  background-color: ${colors.mediumLightGray};
  height: 50px;
  font-size: 1rem;
  font-weight: 600;
  width: 100%;
  text-align: center;
  margin: auto;
  box-sizing: border-box;
  position: sticky;
  z-index: 5;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  padding-top: 12px;
  top: 64px;
`;

interface INavHeaderTopContainer {
  width: number;
  offset: number;
  disableTransition?: boolean;
}
const NavHeaderTopContainer = styled(NavHeaderContainer, {
  shouldForwardProp: prop => shouldForwardProps(prop, ['width', 'offset']),
})<INavHeaderTopContainer>(
  ({ width, offset, theme, disableTransition }) => `

  top: 0;
  background-color: transparent;
  height: 64px;
  transition:${disableTransition ? 'none' : 'all 0.3s ease-in'};

  position:relative;
  ${theme.breakpoints.down('xmd')}{
     flex-direction:column;
    position: relative;
    height: auto;
    justify-content: flex-start;
    align-items: flex-start;
    background: ${colors.purple15};
    margin-bottom:${theme.spacing(3)};
    padding: 24px 12px;
    border-radius: 8px;
  }
  ::before {
    content: "";
    display: block;
    border-bottom: 3.5px solid ${colors.smartNApostrophe};
    width: ${width}px; /* width setting */
    transform: translateX(${offset + 10}px); /* position setting */
    transition:${disableTransition ? 'none' : 'transform 0.5s, width 0.5s'}; /* sliding smoothness */
   
    position:absolute;
    bottom:0;
    left:0;
  
  }
`,
);

const SectionHeader = styled('div', {
  shouldForwardProp: prop => shouldForwardProps(prop, ['isSelected', 'disabled']),
})<ISectionHeader>(
  ({ isSelected, theme, disabled }) => `
  display: flex;
  gap: 12px;
  align-items: center;
  cursor: ${disabled ? 'not-allowed' : 'pointer'};
  color: ${colors.primary};
  transition: opacity 0.3s ease-in;
  &:hover {
    opacity: ${disabled ? 1 : 0.7};
  }
  font-size:0.875rem;
  box-sizing:border-box;
  padding-left: 8px;
  padding-right: 8px;
  height:100%;
  min-width:140px;
  padding-bottom:12px;
  ${theme.breakpoints.up('xmd')}{
  justify-content:center;

}
  svg {
    width:24px;
    height:24px;
  }
  ${theme.breakpoints.down('xmd')}{
  min-width:0;
  padding-left:0px;
  padding-right:20px;
  position:relative;
  ::before {
    content: "";
    display: block;
    position:absolute;
    transition: all 0.3s ease-in;
    border-bottom: 3.5px solid ${colors.smartNApostrophe};
    width: ${isSelected ? '100%' : 0};
    bottom:0;
   
  }
   }
`,
);

const SectionHeaderText = styled('div')`
  font-weight: 700;
`;

export { NavHeaderContainer, SectionHeader, SectionHeaderText, NavHeaderTopContainer };
