import { styled } from '@mui/material/styles';
import { colors } from '../../../themes';
import Button from '../../components/Button';
import { opacityInAnimation } from '../../sharedStyled';

const PageContainer = styled('div')`
  background: ${colors.white};
  min-height: calc(100vh - 64px);
  box-sizing: border-box;
  padding-top: min(60px, 3.75rem);
  display: flex;
  justify-content: center;
  padding-left: 16px;
  padding-right: 16px;
  ${opacityInAnimation()};
`;

const ContentContainer = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 760px;
`;

const IconContainer = styled('div')`
  margin-bottom: min(24px, 1.5rem);
`;

const HeaderText = styled('h1')`
  margin: 0;

  font-weight: 700;
  color: ${colors.black};
  margin-bottom: min(16px, 1rem);
  text-align: center;
  font-size: clamp(1.5rem, 2.5vw, 3rem);
`;

const Body = styled('div')`
  text-align: center;
  margin-bottom: min(24px, 1.5rem);
  color: ${colors.black};
  front-size: 1rem;
`;

const StyledButton = styled(Button)`
  margin-bottom: min(24px, 1.5rem);
`;

export { PageContainer, ContentContainer, IconContainer, HeaderText, Body, StyledButton };
