import { IStepComponent } from '../types';
import { en } from '../../../i18n';
import { OutlinedInput } from '@mui/material';

import { InputContainer, QuestionHeader } from './styled';
import React from 'react';
import useStep from './useStep';
import { EApplyTypes } from '../../types';

const { apply } = en;
const { experience } = apply;

const Experience = ({ question }: IStepComponent) => {
  const { title, onChangeAnswer, currentResponse, currentType } = useStep({ question });

  const onChangeCurrentExperience = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const regex = /^\d*\.?\d*$/;
    if (regex.test(value)) onChangeAnswer(value);
  };

  const renderTitle = () => {
    if (currentType === EApplyTypes.JOB_APP) return experience.titleJobApp;
    return experience.title;
  };

  return (
    <>
      <QuestionHeader>{renderTitle()}</QuestionHeader>
      {title}
      <InputContainer>
        <OutlinedInput
          type="text"
          size="medium"
          placeholder={experience.placeHolder}
          onChange={onChangeCurrentExperience}
          value={currentResponse}
          autoFocus
          notched={false}
          key={'experience'}
        />
      </InputContainer>
    </>
  );
};

export default Experience;
