import { styled } from '@mui/material/styles';
import { colors } from '../../../themes';
import { opacityInAnimation } from '../../sharedStyled';
import { shouldForwardProps } from '../../utils';

const TabContainer = styled('div')`
  margin-left: 64px;
  ${opacityInAnimation()}
  margin-right: 64px;

  ${props => props.theme.breakpoints.down('md')} {
    margin-left: 32px;
    margin-right: 32px;
  }
  ${props => props.theme.breakpoints.down('sm')} {
    margin-left: 16px;
    margin-right: 16px;
  }
`;

const TabTitle = styled('h1')`
  margin: 0;
  color: ${colors.black10};
  display: flex;
  gap: 8px;
  margin-bottom: 24px;
  font-size: 1.25rem;
  font-weight: 700;
`;

const HomeCard = styled('div')`
  background-color: ${colors.lightestGrey};
  border: 1px solid ${colors.lightGrey};
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  padding: 40px;
  padding-right: 8px;
  width: 80%;
  margin: auto;
  position: relative;
  ${props => props.theme.breakpoints.down('md')} {
    padding: 30px 20px;
    width: 80%;
  }
`;

const CardHeader = styled('div')`
  display: flex;
  justify-content: space-between;
  margin-bottom: 50px;
  padding-right: 32px;
  ${props => props.theme.breakpoints.down('md')} {
    padding-right: 0;
  }
  ${props => props.theme.breakpoints.down('sm')} {
    flex-direction: column;
  }
`;

const CardTitle = styled('div')`
  font-size: 1.75rem;
  font-weight: 700;
  ${props => props.theme.breakpoints.down('md')} {
    margin-bottom: 24px;
  }
`;

const CardSubtitle = styled('div')`
  font-size: 1.25rem;
  font-weight: 700;
  padding-right: 32px;
  text-align: center;
  ${props => props.theme.breakpoints.down('md')} {
    padding-right: 0;
  }
`;

const TabContent = styled('div')`
  border-radius: 8px;
  background-color: ${colors.white};
  width: 100%;
  box-sizing: border-box;
  padding: 32px;
  margin-bottom: 20px;
  ${props => props.theme.breakpoints.down('sm')} {
    padding: 32px 16px;
  }
`;

const ContentTitle = styled('h1')`
  margin: 0;
  font-weight: 700;
  font-size: 1.125rem;
  color: ${colors.black};
  margin-bottom: 24px;
`;

const InnerContentTitle = styled(ContentTitle)`
  font-size: 1rem;
`;

const InnerContentTitleLargeMargin = styled(InnerContentTitle)`
  margin-bottom: 48px;
`;

const SmallTitleMargin = styled(ContentTitle)`
  margin-bottom: 8px;
`;

const InnerContentTitleNoMargin = styled(InnerContentTitle)`
  margin-bottom: 0px;
`;

const ContentSubTitle = styled('h2')`
  margin: 0;
  margin-bottom: 48px;
  font-size: 1rem;
  font-weight: 600;
`;

interface ITopicBoxContainer {
  isSelected: boolean;
  isLarge?: boolean;
}
const TopicBoxContainer = styled('div', {
  shouldForwardProp: prop => shouldForwardProps(prop, ['isSelected', 'isLarge']),
})<ITopicBoxContainer>(({ isSelected, theme, isLarge }) => {
  return `
  padding: 12px 18px;
  height:${isLarge ? '52px' : '44px'};
  border: 1px solid ${colors.grey600};
  background-color: ${colors.white};
  display: flex;
  gap: 8px;
  width: calc(33% - 12px);
  border-radius: 4px;
  align-items: center;
  box-sizing: border-box;
  transition: all 0.2s ease-in;
  cursor: pointer;
  &:hover{
    border: 1px solid ${colors.purple20};
  }
   ${theme.breakpoints.down('lg')} {
      height:auto;
  }

     ${theme.breakpoints.down('md')} {
      width: calc(50% - 12px);
  }
    ${theme.breakpoints.down('sm')} {
      width:100%;
  }

  ${isSelected ? `background-color:${colors.purple5};` : ''}
  ${isSelected ? `border: 1px solid ${colors.purple20}};` : ''}

  `;
});

const LargeTopicBoxContainer = styled(TopicBoxContainer)`
  height: 52px;
`;

const TopicBoxesRow = styled('div')`
  display: flex;
  gap: 12px;
  flex-wrap: wrap;
  margin-bottom: 24px;
  box-sizing: border-box;
  ${props => props.theme.breakpoints.down('sm')} {
    flex-direction: column;
  }
`;

const TopicText = styled('span')`
  font-size: 0.875rem;
  font-weight: 600;
  color: ${colors.black};
  &::first-letter {
    text-transform: capitalize;
  }
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
  ${props => props.theme.breakpoints.down('xmd')} {
    overflow-x: hidden;
    text-overflow: unset;
    white-space: normal;
    overflow-wrap: break-word;
  }
`;

export {
  TabContainer,
  TabTitle,
  HomeCard,
  CardHeader,
  CardTitle,
  CardSubtitle,
  TabContent,
  ContentTitle,
  ContentSubTitle,
  SmallTitleMargin,
  TopicBoxContainer,
  TopicBoxesRow,
  TopicText,
  InnerContentTitle,
  InnerContentTitleLargeMargin,
  InnerContentTitleNoMargin,
  LargeTopicBoxContainer,
};
