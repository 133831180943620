import { ExitCardsContainer, Title } from './styled';
import { en } from '../../../i18n';
import Button from '../../components/Button';
import { useDispatch } from '../../hooks';
import { actions, applySelector } from '../redux/slice';
import { EApplyNewQuiz, EApplyQuizState, EApplyTypes } from '../../types';
import { ReactComponent as PurpleCheckMark } from '../../../images/icons/apply/purpleCheckMark.svg';
import { ReactComponent as GreyCheckMark } from '../../../images/icons/apply/greyCheckMark.svg';
import ExitQuizCard from './ExitQuizCard';
import useStartApplyEnhance from '../useStartApplyEnhance';
import { useSelector } from 'react-redux';
import { useRef } from 'react';

const { apply } = en;
const { exit } = apply;

const Profile = () => {
  const { dispatch } = useDispatch();
  const {
    applyQuizState,
    renderEnhanceButton,
    currentApplyAttempt,
    questionAnswers,
    loadingStartQuiz,
  } = useStartApplyEnhance();

  const { newQuiz } = useSelector(applySelector.allState);
  const wasNewBase = useRef(false);

  const reset = () => {
    //dispatch(actions.setCurrentType({ type: EApplyTypes.BASE }));
    dispatch(actions.setNewQuizType({ quizType: EApplyNewQuiz.NONE }));
    dispatch(actions.setCurrentQuestionIndex({ index: 0 }));
  };

  const renderTitles = () => {
    const getTitle = () => {
      switch (newQuiz) {
        case EApplyNewQuiz.BASE:
        case EApplyNewQuiz.JOB_APP:
          wasNewBase.current = true;
          return exit.titleNew;
        case EApplyNewQuiz.ENHANCEMENT:
          if (loadingStartQuiz) {
            if (wasNewBase.current) return exit.titleNew;
            else return null;
          }

          return exit.titleEnahcned;
      }

      return null;
    };

    const title = getTitle();
    if (!title) return null;

    return <Title>{title}</Title>;
  };

  const renderApplyQuizButton = () => {
    return (
      <Button size="small" onClick={reset} variant="underlinedSpan">
        {exit.resetText}
      </Button>
    );
  };

  const renderEnhanceProfileIcon = () => {
    if (applyQuizState === EApplyQuizState.ENHANCE_DONE) return PurpleCheckMark;

    return GreyCheckMark;
  };

  const renderApplyProfileIcon = () => {
    return PurpleCheckMark;
  };

  return (
    <ExitCardsContainer>
      {renderTitles()}
      <ExitQuizCard
        Icon={renderEnhanceProfileIcon()}
        title={exit.enhanceProfileTitle}
        subTitle={exit.enhancePorfileSubTitle}
        action={renderEnhanceButton()}
      />
      <ExitQuizCard
        Icon={renderApplyProfileIcon()}
        title={exit.applyQuizTitle}
        subTitle={exit.applyQuizSubTitle}
        action={renderApplyQuizButton()}
      />
    </ExitCardsContainer>
  );
};

export default Profile;
