import { KeyboardEvent, useState, useEffect } from 'react';
import { useLocation } from 'react-router';
import { actions } from '../../NurseGPT/redux/slice';
import useCommonNurseGPT from './useCommonNurseGPT';
import useNavigations from '../useNavigations';
import useWebSessionAuth from '../useWebSessionAuth';
import { en } from '../../../i18n';
import { endpoints } from '../../constants';
import useKeyDownPress from '../useKeyDownPress';
import useVariableKeyDownPress from '../useVariableKeyDownPress';

const { prompts } = en.nurseGPT;

const usePublicNurseGPT = () => {
  const {
    state: {
      prompt,
      showErrorModal,
      typedResponse,
      svgLoader,
      typedResponseRef,
      inputRef,
      messagesWrapperRef,
      copyToolTipText,
      loadingResponse,
      currentSessionId,
      sessions,
      errors,
      currentSession,
      chat,
      isUnstarted,
      initialConnection,
      reconnectStatus,
      isReconnecting,
    },
    control: {
      sendJsonMessage,
      setPrompt,
      refresh,
      setCopyTooltipText,
      dispatch,
      handlePromptChange,
      handleStartNewChat,
      handleExampleClick,
      getPlaceholder,
      setLastPromptAttempt,
    },
  } = useCommonNurseGPT({
    socketUrl: endpoints.publicNurseGPTWebSocket,
    useSocketAuthorization: false,
    useSocketWebSessionAuthorization: true,
  });
  const { webSession, loading: loadingWebSession } = useWebSessionAuth();
  const location = useLocation();
  const { navigateToRegistration, navigateToLogin } = useNavigations();
  const [showDiscardSessionModal, setShowDiscardSessionModal] = useState(false);
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [showWelcomeModal, setShowWelcomeModal] = useState(false);
  const query = new URLSearchParams(location.search);
  const redirectedPromptIndex = query.get('prompt');
  const hideExamples = redirectedPromptIndex !== null;

  const handlePromptSubmit = () => {
    dispatch(actions.submitPrompt({ prompt, currentSessionId, sendJsonMessage, fileUpload: null }));
    setLastPromptAttempt(prompt);
    setPrompt('');
  };

  const onKeyDown = (e: KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.code === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      if (!prompt || loadingResponse) setPrompt(prompt + '\n');
      else handlePromptSubmit();
    }
  };

  const toggleDiscardSessionModal = () => {
    if (!showDiscardSessionModal && isUnstarted) return;
    setShowDiscardSessionModal(!showDiscardSessionModal);
  };

  const toggleUploadModal = () => {
    setShowUploadModal(!showUploadModal);
  };

  const toggleWelcomeModal = () => {
    setShowWelcomeModal(!showWelcomeModal);
  };

  useEffect(() => {
    if (webSession) toggleWelcomeModal();
  }, []);

  useEffect(() => {
    if (webSession && redirectedPromptIndex && initialConnection) {
      inputRef.current?.focus();
      setPrompt(prompts.redirectedPrompts[Number(redirectedPromptIndex)]);
    }
  }, [redirectedPromptIndex, webSession, initialConnection]);

  useVariableKeyDownPress('Escape', () => {
    if (showWelcomeModal) setShowWelcomeModal(false);
    if (showDiscardSessionModal) setShowDiscardSessionModal(false);
  });

  return {
    state: {
      prompt,
      chat,
      messagesWrapperRef,
      typedResponseRef,
      loadingResponse,
      isUnstarted,
      sessions,
      inputRef,
      typedResponse,
      currentSession,
      svgLoader,
      showErrorModal,
      copyToolTipText,
      errors,
      showDiscardSessionModal,
      showUploadModal,
      showWelcomeModal,
      hideExamples,
      webSession,
      loadingWebSession,
      initialConnection,
      reconnectStatus,
      isReconnecting,
    },
    control: {
      setPrompt,
      handlePromptChange,
      handlePromptSubmit,
      handleStartNewChat,
      onKeyDown,
      handleExampleClick,
      getPlaceholder,
      refresh,
      setCopyTooltipText,
      toggleDiscardSessionModal,
      toggleUploadModal,
      toggleWelcomeModal,
      navigateToRegistration,
      navigateToLogin,
    },
  };
};

export default usePublicNurseGPT;
