import { IStepComponent } from '../types';
import { en } from '../../../i18n';
import { OutlinedInput } from '@mui/material';
import { InputContainer, QuestionHeader } from './styled';
import React from 'react';
import useStep from './useStep';
import PreferredNameOnboarding from '../../OnBoarding/PreferredName';
import { mainSteps } from '../../OnBoarding/constants';

const { apply } = en;
const { experience } = apply;

const PreferredName = ({ question }: IStepComponent) => {
  const { onChangeAnswer, currentResponse } = useStep({ question });

  const onboardingStep = mainSteps.find(q => q.onboardingQuestionId === question.id);
  const preferredNameProps = {
    question,
    currentStep: onboardingStep || {},
  };

  const onChangeName = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    onChangeAnswer(value);
  };

  return (
    <>
      <PreferredNameOnboarding {...preferredNameProps}></PreferredNameOnboarding>
      <InputContainer>
        <OutlinedInput
          type="text"
          size="medium"
          placeholder={experience.placeHolder}
          onChange={onChangeName}
          value={currentResponse}
          autoFocus
          notched={false}
          key={'name'}
        />
      </InputContainer>
    </>
  );
};

export default PreferredName;
